import axios from "axios";
import store from "../store";
// Create axios client, pre-configured with baseURL
// http://13.49.65.63:3004
const LOCAL = "http://localhost:3010";
const ONLINE = "https://api.selectiveyazilim.com/leta";

let APIKit = axios.create({
  baseURL: ONLINE,
  timeout: 100000,
});

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = (token, data) => {
  APIKit.defaults.headers.common = { Authorization: `${token}` };
};

APIKit.interceptors.response.use(
  (response) => {
    return response;
  },
  (value) => {
    if (value?.response?.status === 401) {
      store.logOut();
      window.location.href = "/auth/login";
    } else if (value?.response?.status === 403) {
      store.logOut();
      window.location.href = "/auth/login";
    } else {
      Promise.reject(value);
    }
  }
);

export default APIKit;
