import React from "react";
import styles from "./print.module.css";
import moment from "moment";
import { numberToMoney } from "../../utils/util";
import "moment/locale/es";
import TopBanner from "../../images/topbanner.jpeg";
import APIKit from "../../shared/APIKit";
import OldPdf from "./OldPdf";
import NewPdf from "./NewPdf";
import { returnTopBanner } from "../../images/TopBanners";
import banks from "./banks";
import BankItems from "./BankItems";

class PrintPDF extends React.Component {
  constructor(props) {
    super(props);
  }

  trMonths = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  trWeeks = [
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ];

  enMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  enWeeks = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  numberToMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  }

  floatParser = (data) => {
    if (parseFloat(data)) return parseFloat(data);
    else return 0;
  };

  otherName = (stkno) => {
    if (stkno) {
      APIKit.get(`/api/product/otherName/${stkno}`).then(({ data }) => {
        return data;
      });
    }
  };

  kdvResults() {
    var kdvOran = 0;
    var kdvFiyat = 0;
    for (var i = 0; i < this.props.orderCart.length; i++) {
      kdvOran += this.floatParser(this.props.orderCart[i].kdv);
      kdvFiyat +=
        (((100 - this.props.orderCart[i].discount3) / 100) *
          ((100 - this.props.orderCart[i].discount2) / 100) *
          ((100 - this.props.orderCart[i].discount1) / 100) *
          this.props.orderCart[i].price *
          this.props.orderCart[i].count -
          this.props.orderCart[i].totalDiscount +
          this.floatParser(this.props.orderCart[i].kataForez) *
            this.props.orderCart[i].count +
          this.floatParser(this.props.orderCart[i].kumasFarki) *
            this.props.orderCart[i].count) *
        (this.floatParser(this.props.orderCart[i].kdv) / 100);
    }

    kdvOran = kdvOran / this.props.orderCart.length;
    return {
      kdvOran,
      kdvFiyat,
    };
  }

  render() {
    moment.locale(this.props.printInfo.lang, {
      months:
        this.props.printInfo.lang === "tr" ? this.trMonths : this.enMonths,
      weekdays:
        this.props.printInfo.lang === "tr" ? this.trWeeks : this.enWeeks,
    });

    const bankFiltered =
      banks[
        this.props.printInfo.type === "İHRACAT"
          ? this.props.printInfo.type + this.props.printInfo.type2
          : this.props.printInfo.type
      ];

    return (
      <div className={styles.container}>
        <div className={styles.subcont}>
          <div className={styles.topbanner}>
            <img
              style={{ height: "100%", width: "100%" }}
              src={
                returnTopBanner(
                  this.props.printInfo.lang,
                  this.props.auth.magaza
                ) || TopBanner
              }
            />
          </div>
          <div className={styles.contract}>
            <h3 className={styles.date}>
              {moment().format("DD MMMM YYYY dddd")}
            </h3>
            <h2 className={styles.header}>
              {this.props.printInfo.lang === "tr"
                ? "SATIŞ SÖZLEŞMESİ"
                : "PROFORMA INVOICE"}
            </h2>
            <div className={styles.details}>
              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr"
                    ? "PROJE ADI :"
                    : "PROJECT NAME :"}{" "}
                </label>
                <label>{this.props.offerDetails.offerName}</label>
              </div>
              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr"
                    ? "YETKİLİ KİŞİ :"
                    : "CONTACT :"}{" "}
                </label>
                <label></label>
              </div>
              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr"
                    ? "YETKİLİ KİŞİ TEL :"
                    : "PHONE :"}{" "}
                </label>
                <label></label>
              </div>
              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr"
                    ? "ŞİRKET UNVANI :"
                    : "CONSIGNEE :"}{" "}
                </label>
                <label>{this.props.current.unvan}</label>
              </div>

              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr" ? "ŞEHİR :" : "CITY :"}{" "}
                </label>
                <label>{this.props.current.il}</label>
              </div>
              <div className={styles.row}>
                <label>
                  {this.props.printInfo.lang === "tr"
                    ? "ŞİRKET TEL :"
                    : "PHONE :"}{" "}
                </label>
                <label>{this.props.current.telefon}</label>
              </div>
              <div className={styles.row}>
                <label>E-MAIL : </label>
                <label>{this.props.current.mail}</label>
              </div>
            </div>
          </div>
          {/* <OldPdf
            items={this.props.orderCart}
            lang={this.props.printInfo.lang}
            hide={this.props.printInfo.hide}
            cartPriceWithoutKdv={this.props.cartPriceWithoutKdv}
            currenyUnit={this.props.currenyUnit}
          /> */}
          <NewPdf
            items={this.props.orderCart}
            lang={this.props.printInfo.lang}
            hide={this.props.printInfo.hide}
            cartPriceWithoutKdv={this.props.cartPriceWithoutKdv}
            currenyUnit={this.props.currenyUnit}
          />
          <div className={styles.totals}>
            <div className={styles.notes}>
              {this.props.printInfo.lang === "tr" ? "NOTLAR" : "NOTES"}
            </div>

            <div className={styles.note}>
              <h3>{this.props.offerDetails.explanation}</h3>
            </div>
            <div className={styles.totalprices}>
              <div className={styles.price}>
                <div>
                  {this.props.printInfo.lang === "tr" ? "ARA TOPLAM" : "TOTAL"}
                </div>
                <div>
                  {numberToMoney(
                    this.props.cartPriceWithoutKdv,
                    this.props.currenyUnit
                  )}{" "}
                </div>
              </div>
              <div className={styles.price}>
                <div>
                  {this.props.printInfo.lang === "tr" ? "KDV" : "TAX"} (
                  {parseFloat(this.kdvResults().kdvOran).toFixed(2)}%)
                </div>
                <div>
                  {numberToMoney(
                    this.kdvResults().kdvFiyat,
                    this.props.currenyUnit
                  )}
                </div>
              </div>
              <div className={styles.price}>
                <div>
                  {this.props.printInfo.lang === "tr"
                    ? "GENEL TOPLAM"
                    : "GRAND TOTAL"}
                </div>
                <div>
                  {numberToMoney(
                    this.props.totalCartPrice,
                    this.props.currenyUnit
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.explanation}>
            <div className={styles.left}>
              {this.props.printInfo.lang === "tr"
                ? "AÇIKLAMALAR"
                : "EXPLANATION"}
            </div>
            <div className={styles.list}>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "1) İŞ BU TEKLİF 10(ON) İŞ GÜNÜ GEÇERLİDİR"
                  : "1) THIS QUOTATION IS VALID FOR  10 DAYS . IN CASE OF MISSING THE 10 DAYS, CLIENT COULD EXPECT REVISES ON THE PRICES/DEADLINES/MATERIAL AVAILABILITIES."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "2) FİYATLARIMIZA NAKLİYE VE MONTAJ DAHİL DEĞİLDİR. TALEP EDİLDİĞİ TAKDİRDE FATURAYA YANSITILACAKTIR."
                  : "2) WE WORK AS EX-WORKS COMPANY FOR DELIVERY TYPE. ANY OTHER DELIVERY TYPE WILL BE QUOTED SEPERATELY. "}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "3) SİPARİŞ ONAYINDAN SONRA ÜRÜNLER İLE İLGİLİ DEĞİŞİKLİK HAKKI SADECE ÜRETİCİ FİRMADA SAKLIDIR."
                  : "3) INVOICE WILL BE ALWAYS %100 OF THE TOTAL AMOUNT."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "4) KDV DAHİL TOPLAM TUTARIN %50 AVANS ÖDEMESİ TAHSİL EDİLDİĞİNDE SİPARİŞ ONAYLANMIŞ SAYILIP, 1 İŞ GÜNÜ SONRASI ÜRETİMİN İLK GÜNÜ OLACAKTIR."
                  : "4) CASH PAYMENTS ARE NOT PRIMARY ACCEPTED. WE CONSIDER THE SAFEST AND OFFICIAL WAY OF BANK TRANSFERS AT ALL TIMES."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "5) KALAN TUTAR ÜRÜN TESLİMİNDEN ÖNCE TAHSİL EDİLECEKTİR."
                  : "5) DURING THE LOGISTICS PERIOD THE TRUCKS/CONTAINERS SHOULD ARRIVE TO OUR LOADING AREA IN THE FACTORY. WE DO NOT PROVIDE SERVICES FOR SENDING THE ITEMS TO ANY OTHER LOADING AREA."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "6) STANDART ÜRÜNLER İÇİN TESLİMAT SÜRESİ 35 (OTUZBEŞ) İŞ GÜNÜDÜR."
                  : "6) THE PAYMENTS SHOULD BE SENT FROM THE ACCOUNT/COMPANY NAME WHICH WILL BE USED ON THE INVOICE AS WELL. INVOICE TO ONE ADDRESS PAYMENT FROM ANOTHER ADDRESS IS NOT RECOMMENDED."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "7) ÖZEL ÜRETİLECEK TASARIM ÜRÜNLERDE TESLİM SÜRESİ AYRICA FABRİKADAN ALINARAK BİLDİRİLECEKTİR."
                  : "7) AFTER 45 DAYS FROM YOUR BALANCE PAYMENT, THE ITEMS SHOULD BE RETRIEVED, ON THE 3RD WARNING, THE ITEMS WILL BE DISPOSED AND THE PAYMENTS WILL NOT BE RETURNED. PLEASE ADDRESS ANY POTENTIAL ISSUES LIKE THIS PRIOR TO THE WARNING PROCESS."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "8) SİPARİŞTEKİ ÜRÜNLER DEPODA HAZIR OLDUĞUNDA VE TERMİNİ GELDİĞİ TARİHİNDEN İTİBAREN 30 GÜN SONUNDA ALINMADIĞI TAKDİRDE, MÜŞTERİMİZE SİPARİŞ TUTARI ÜZERİNDEN AYLIK %3 DEPO KİRASI YANSITILACAK OLUP, SİPARİŞ TUTARININ TAMAMI TAHSİL EDİLECEKTİR."
                  : "8) AFTER THE ORDER IS CONFIRMED BY BOTH SIDES AS SIGNED AND STAMPED, IF THERE IS A NEED FOR REVISION, LETA SHOULD BE NOTIFIED WHETHER IT WOULD BE OKAY OR NOT TO MAKE REVISES."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "9) İÇERİĞİ DOĞAL MERMER OLAN ÜRÜNLERİMİZDE SATIŞTAN SONRA OLUŞACAK LEKE, İZ (ÇAY LEKESİ, LİMON, ASİT, V.B.) GİBİ DURUMLARDAN FİRMAMIZ SORUMLU DEĞİLDİR."
                  : "9) THE PRODUCTION STARTS AFTER THE %50 OF THE TOTAL AMOUNT IS RECEIVED BY OUR FINANCE DEPARTMENT."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "10) METAL ÇERÇEVELİ, KAPLAMALI, BOYALI ÜRÜNLERDE KİMYASAL BAZLI MADDELER  KULLANIMI SONRASINDA OLUŞACAK PROBLEMLERDEN FİRMAMIZ SORUMLU DEĞİLDİR."
                  : "10) THE REST OF BALANCE SHOULD BE COLLECTED 7 DAYS PRIOR TO THE LOADING. IF NOT THE SUPPLIER HAS THE RIGHT NOT TO ALLOW ANY LOADING."}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "11) İŞ BU ÜRÜN SATIŞ SÖZLEŞMESİNDE YER ALAN STANDART ŞARTLAR DAHİL TÜM ŞARTLAR MÜNFERİDEN MÜZAKERE EDİLEREK TÜKETİCİ TARAFINDAN KABUL EDİLMİŞTİR. SÖZLEŞME OKUNARAK VE MÜZAKERE EDİLEREK İMZALANMIŞTIR."
                  : "11) IF NOT MENTIONED EXCLUSIVELY, ALL THE CHAIRS ARE MADE FROM 1ST CLASS BEECHWOOD. "}
              </div>
              <div className={styles.item}>
                {this.props.printInfo.lang === "tr"
                  ? "12) KİŞİSEL VERİLERİNİZ ÖZCANLAR SANDALYE SAN. VE TİC. LTD. ŞTİ. TARAFINDAN İLGİLİ MEVZUATA UYGUN OLARAK MUHAFAZA EDİLMEKTEDİR. 6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU GEREĞİNCE İLGİLİ HAKLARINIZI VE AYDINLATMA METNİNİ ‘’leta.com.tr/kisisel-verilerin-korunması/’’ WEB SİTESİ ADRESİMİZDEN ÖĞRENEBİLİRSİNİZ."
                  : "12) EXCEPT THE FORCE MAJEURE CONDITIONS, THE LEADING TIME IS  MINIMUM 30 WORKING DAYS."}
              </div>
              {this.props.printInfo.lang !== "tr" && (
                <div className={styles.item}>
                  13) MUTUAL SIGNATURE AND STAMPS ARE NEEDED FOR THIS ORDER TO
                  BE ACCEPTED.
                </div>
              )}
            </div>
          </div>
          <BankItems banks={bankFiltered} lang={this.props.printInfo.lang} />

          <div className={styles.bottom}>
            <div className={styles.bottombanner}>
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "98%",
                  marginRight: "auto",
                }}
                src={`https://cloudselective.s3.eu-central-1.amazonaws.com/leta/imzalar/${this.props.auth.id}.jpg`}
              />
            </div>
            <div className={styles.getorder}>
              <h3>LETA SANDALYE</h3>
              <span>
                {this.props.printInfo.lang === "tr"
                  ? "(İSİM/SOYİSİM/İMZA)"
                  : "(NAME/SIGNATURE)"}
              </span>
            </div>
            <div className={styles.setorder}>
              <h3>
                {this.props.printInfo.lang === "tr"
                  ? "SİPARİŞİ VEREN"
                  : "CONSIGNEE"}
              </h3>
              <span>
                {" "}
                {this.props.printInfo.lang === "tr"
                  ? "(İSİM/SOYİSİM/İMZA)"
                  : "(NAME/SIGNATURE)"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPDF;
