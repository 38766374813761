import React, { useRef, useCallback, useState, useEffect } from "react";
import { Modal, Input, Select, Button, Upload, message } from "antd";
import styles from "./productimagemodal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-carousel-minimal";
import { ADD_TO_CART, UPDATE_initials } from "../../actions/types";
import s3Config from "../../config/s3Config";
import { AiOutlineUpload, AiOutlinePlus } from "react-icons/ai";
import S3 from "react-aws-s3";
import ImageViewer from "react-simple-image-viewer";
import { Formik } from "formik";
import APIKit from "../../shared/APIKit";
import { numberToMoney } from "../../utils/util";
import { inject, observer } from "mobx-react";
import { specificField } from "../../utils/specificField";

const ProductImageModal = inject("MainStore")(
  observer(
    ({
      isOpen,
      setIsOpen,
      selectedProduct,
      countDown,
      refreshFilteredData,
      setSelectedProduct,
      MainStore,
    }) => {
      const [images, setImages] = React.useState([]);
      const { Option } = Select;
      const [count, setCount] = React.useState(1);
      const [isViewerOpen, setIsViewerOpen] = React.useState(false);
      const [loading, setLoading] = React.useState(true);
      const [state, setState] = React.useState({
        previewVisible: false,
        previewImage: "",
        previewTitle: "",
        fileList: [],
      });
      const { TextArea } = Input;
      const [currenyUnit] = React.useState(
        MainStore.current ? MainStore.current.parabirimi : " TL"
      );
      const [configuration, setConfiguration] = React.useState({});
      const [combinationData, setCombinationData] = React.useState(0);
      const [combinationInner, setCombinationInner] = React.useState({});
      const [visibleKumasSec, setVisibleKumasSec] = React.useState(false);
      const [mlzadi2, setMlzadi2] = React.useState("");
      const [listeFiyati, setListeFiyati] = React.useState(0);
      const [specs, setSpecs] = React.useState({
        agactipi: "",
        boya1: "",
        boya2: "",
        boya3: "",
        logo: "",
      });
      const formikRef = useRef();
      const [kumasOzellikleri, setKumasOzellikleri] = useState({
        kumasKat: "",
        kumasTip: "",
        kumasGrup: "",
      });
      const [initials, setInitials] = React.useState({
        discount1: "",
        discount2: "",
        discount3: "",
        price: "",
        totalDiscount: "",
        totalPrice: "",
        ahsapmetal: "",
        derikumas: "",
        kumasmus: false,
        aciklama: "",
        count: 1,
        isNew: true,
        grupadi: "TM MASA",
        images: [],
        files: [],
        kdv: 8,
        ozel1: "",
        ozel2: "",
        ozel3: "",
        webstkno: Math.round(new Date().getTime() / 1000),
        stkno: "",
        stokadi: "",
        agactipi: "",
        boya1: "",
        boya2: "",
        logo: "",
        boya3: "",
        uruntipi: "",
        combination: null,
        kumas: {
          kod: "kumas",
          label: "Kumaş",
          value: "",
        },
        combinationData: {},
        configuration: {},
        kumasFarki: "",
        kataForez: "",
      });

      const handleConfigurations = useCallback(() => {
        setLoading(true);

        APIKit.post("/api/product/configurations", {
          stkno: selectedProduct.stkno,
        }).then(({ data }) => {
          console.log('CONFIGURATIONS')
          console.log(data)
          setMlzadi2(data.mlzadi2);
          setListeFiyati(data.lstFiyat);

          formikRef.current.setFieldValue("price", parseFloat(data.lstFiyat).toFixed(2));

          if (data.specs) {
            setConfiguration({ specs: data.specs });
          } else setCombinationData({ specs: {} });

          if (
            MainStore.kumas[0] ||
            MainStore.boya1[0] ||
            MainStore.boya2[0] ||
            MainStore.boya3[0] ||
            MainStore.agactip[0] ||
            MainStore.logo[0] ||
            MainStore.kumasKategoriler[0]
          ) {
            setLoading(false);
          } else {
            Promise.all([
              APIKit.get("/api/specs"),
              APIKit.post("/api/specs/renk", { stkno: selectedProduct.stkno }),
              APIKit.get("/api/specs/kumas-kat"),
            ]).then(([kumas, renk, kats]) => {
              MainStore.setKumas(kumas.data);
              MainStore.setOtherSpecs(renk.data);
              MainStore.setKumasKategoriler(kats.data);

              setLoading(false);
            });
          }
        });
      }, [selectedProduct.stkno]);

      useEffect(() => {
        console.log(selectedProduct);
        const isSelected = MainStore.kumasKategoriler.find((kumasKat) => {
          if (
            kumasKat.tipi === kumasOzellikleri.kumasTip &&
            kumasKat.grup === kumasOzellikleri.kumasGrup &&
            kumasKat.kategori === kumasOzellikleri.kumasKat
          ) {
            return kumasKat;
          }
        });

        if (isSelected)
          formikRef.current.setFieldValue("kumasFarki", isSelected.fiyat);
      }, [setKumasOzellikleri, kumasOzellikleri]);

      React.useEffect(() => {
        if (isOpen && Object.keys(configuration).length === 0) {
          handleConfigurations();
        }
        if (!isOpen) {
          setConfiguration({});
        }
      }, [isOpen, handleConfigurations, configuration]);

      const handleRemove = (item) => {
        var newProduct = [];

        for (var i = 0; i < Object.keys(initials.files).length; i++) {
          if (initials.files[i].url !== item.url) {
            newProduct.push(initials.files[i]);
          }
        }

        setInitials({ ...initials, files: newProduct });
      };

      const props = {
        customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials,
        }) {
          const config = {
            bucketName: s3Config.bucketName,
            dirName: "leta/files/" + initials.stkno /* optional */,
            region: "eu-central-1",
            accessKeyId: s3Config.accessKeyId,
            secretAccessKey: s3Config.secretAccessKey,
          };

          const ReactS3Client = new S3(config);
          const fileName = Math.floor(Date.now() / 1000) + ".jpg";

          ReactS3Client.uploadFile(file, fileName)
            .then((data) => {
              if (initials["files"]) {
                data["name"] = fileName;
                initials.images.push({
                  uid: data.location,
                  name: fileName,
                  status: "done",
                  url: data.location,
                });
                setInitials({ ...initials, files: initials["files"] });
              } else {
                data["name"] = fileName;
                initials["files"] = [];
                initials["files"].push({
                  uid: data.location,
                  name: fileName,
                  status: "done",
                  url: data.location,
                });
                setInitials({ ...initials, files: initials["files"] });
              }

              initials.files.push({
                uid: data.location,
                name: fileName,
                status: "done",
                url: data.location,
              });
              setInitials({ ...initials, files: initials["files"] });

              onSuccess(
                {
                  uid: data.location,
                  name: fileName,
                  status: "done",
                  url: data.location,
                },
                initials
              );

              message.success("Resim başarıyla yüklendi!");
            })
            .catch((err) => {
              message.error("Resim başarıyla yüklendi!");
            });
        },
      };

      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }

      const floatParser = (data) => {
        if (parseFloat(data)) return parseFloat(data);
        else return 0;
      };

      return (
        <Modal
          title="Ürün Detayları"
          visible={isOpen}
          width={1300}
          style={{
            borderRadius: 15,
          }}
          onCancel={() => {
            setCount(1);
            formikRef.current.resetForm();

            setCombinationData("");
            setCombinationInner({});
            setSpecs({
              agactipi: "",
              boya1: "",
              boya2: "",
              boya3: "",
              logo: "",
            });
            setIsOpen(false);
            setConfiguration({});
          }}
          okText={"Teklife Ekle"}
          cancelText={"İptal Et"}
          footer={true}
        >
          <Formik
            innerRef={formikRef}
            initialValues={initials}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (loading) message.info("Önce konfirgürasyonlar yüklenmeli!");
              else if (Object.keys(MainStore.current).length) {
                values["stokadi"] = selectedProduct.stokadi;
                values["stkno"] = selectedProduct.stkno;
                values["images"] = selectedProduct.images;
                values["files"] = initials.files;
                values["isNew"] = false;
                values["configuration"] = configuration;
                values["combinationData"] = combinationInner;
                values["combination"] = combinationData;
                values["kumas"] = specs.kumas;
                values["boya1"] = specs.boya1;
                values["boya2"] = specs.boya2;
                values["boya3"] = specs.boya3;
                values["lstFiyat"] = listeFiyati;
                values["stokadi2"] = mlzadi2;
                values["agactipi"] = specs.agactipi;
                values["sortNumber"] = MainStore.offerCart.length / 10;

                values = {
                  ...values,
                  ...kumasOzellikleri,
                };

                setSelectedProduct(values);
                if (false) setSelectedProduct(values);
                else {
                  setCombinationData("");
                  setCombinationInner({});

                  setSpecs({
                    agactipi: "",
                    boya1: "",
                    boya2: "",
                    boya3: "",
                    logo: "",
                  });

                  MainStore.addToCart(values);
                  formikRef.current.resetForm();
                  setCount(1);
                  setIsOpen(false);
                  setConfiguration({});
                }
              } else {
                countDown();
                setCount(1);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <div className={styles.mainContainer}>
                <div className={styles.container}>
                  <div className={styles.leftSide}>
                    <div className={styles.top}>
                      <div className={styles.imagePrev}>
                        <div className={styles.header}>
                          <h2 style={{ maxWidth: 160 }}>
                            {selectedProduct.stokadi}
                          </h2>
                        </div>
                      </div>
                      {selectedProduct?.images ? (
                        <img
                          onClick={() => setIsViewerOpen(true)}
                          style={{
                            maxHeight: 190,
                            maxWidth: 190,
                            cursor: "pointer",
                          }}
                          src={
                            selectedProduct.images[0]
                              ? selectedProduct.images[0].url
                              : ""
                          }
                        />
                      ) : null}

                      <div>
                        Adet : {"  "}
                        <Input
                          name="count"
                          value={values.count}
                          type={"number"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={"Adet"}
                          className={styles.input}
                        />
                      </div>
                    </div>
                    {/*
        <div className={styles.imageSlider}>
          <Upload
            {...props}
            listType="picture-card"
            fileList={state.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
          >
            {state.fileList.length >= 8 ? null : uploadButton}
          </Upload>

          <Modal
            visible={state.previewVisible}
            title={state.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={state.previewImage}
            />
          </Modal>
        </div>
        */}
                  </div>

                  <div className={styles.rightItems}>
                    <div className={styles.right}>
                      <div className={styles.row}>
                        <Input
                          disabled={true}
                          name="stokadi"
                          value={selectedProduct.stokadi}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={styles.longinput}
                          placeholder="Ürün Adı"
                        />
                        <Input
                          name="stkno"
                          value={selectedProduct.stkno}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={"Stok Kodu"}
                          className={styles.input}
                          disabled={true}
                        />
                        <Input
                          name="discount1"
                          value={values.discount1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          addonAfter="%"
                          className={styles.input}
                          placeholder="İskonto(1)"
                        />
                        <Input
                          name="kataForez"
                          value={values.kataForez}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          addonAfter={currenyUnit}
                          className={styles.input}
                          placeholder="Kataforez Farkı"
                        />
                      </div>
                      <div className={styles.row}>
                        <Input
                          name="ahsapmetal"
                          value={values.ahsapmetal}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={styles.input}
                          placeholder="Ahşap/Metal Detayı"
                        />
                        <Input
                          name="derikumas"
                          value={values.derikumas}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={styles.input}
                          placeholder="Deri/Kumaş Detayı"
                        />
                        <Input
                          className={styles.input}
                          style={{
                            opacity: 0,
                            cursor: "default",
                          }}
                          disabled
                          placeholder={
                            "Liste Fiyatı: " + numberToMoney(listeFiyati, " TL")
                          }
                        />
                        {values.discount1 ? (
                          <Input
                            name="discount2"
                            value={values.discount2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            addonAfter="%"
                            className={styles.input}
                            placeholder="İskonto(2)"
                          />
                        ) : (
                          <Input
                            name="discount2"
                            className={styles.input}
                            disabled
                            style={{ opacity: 0, cursor: "initial" }}
                            placeholder="İskonto(2)"
                          />
                        )}

                        <Input
                          name="kumasFarki"
                          value={values.kumasFarki}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          addonAfter={currenyUnit}
                          className={styles.input}
                          placeholder="Kumaş Farkı"
                        />
                      </div>
                      <div className={styles.leftSide}>
                        <TextArea
                          name="aciklama"
                          value={values.aciklama}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={styles.textarea}
                          placeholder={"Açıklama"}
                        />
                        <div className={styles.col}>
                          <Input
                            name="price"
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Adet Fiyatı"}
                            className={styles.input}
                          />
                        </div>
                        <div className={styles.right}>
                          <div className={styles.row}>
                            {values.discount2 ? (
                              <Input
                                name="discount3"
                                value={values.discount3}
                                addonAfter="%"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={styles.input}
                                placeholder="İskonto(3)"
                              />
                            ) : (
                              <Input
                                name="discount2"
                                className={styles.input}
                                style={{ opacity: 0, cursor: "initial" }}
                                disabled
                                placeholder="İskonto(2)"
                              />
                            )}

                            <Input
                              name="kdv"
                              value={values.kdv}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              addonAfter="%"
                              className={styles.input}
                              placeholder="KDV"
                            />
                          </div>
                          <div className={styles.row}>
                            <Input
                              name="totalDiscount"
                              value={values.totalDiscount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              addonAfter={currenyUnit}
                              className={styles.input}
                              placeholder="Kalem İskonto"
                            />

                            <Input
                              className={styles.input}
                              disabled={true}
                              value={String(
                                numberToMoney(
                                  (((100 - values.discount3) / 100) *
                                    ((100 - values.discount2) / 100) *
                                    ((100 - values.discount1) / 100) *
                                    values.price *
                                    values.count -
                                    values.totalDiscount +
                                    floatParser(values.kataForez) *
                                      values.count +
                                    floatParser(values.kumasFarki) *
                                      values.count) *
                                    (floatParser(values.kdv) / 100) +
                                    (((100 - values.discount3) / 100) *
                                      ((100 - values.discount2) / 100) *
                                      ((100 - values.discount1) / 100) *
                                      values.price *
                                      values.count -
                                      values.totalDiscount +
                                      floatParser(values.kataForez) *
                                        values.count +
                                      floatParser(values.kumasFarki) *
                                        values.count),
                                  ""
                                )
                              )}
                              addonAfter={currenyUnit}
                              placeholder="Toplam Fiyat"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.row}
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <Input
                          disabled
                          style={{
                            opacity: 0,
                          }}
                          className={styles.input}
                        />
                        <Input
                          disabled
                          style={{
                            opacity: 0,
                          }}
                          className={styles.input}
                        />
                        <Input
                          disabled
                          style={{
                            opacity: 0,
                          }}
                          className={styles.input}
                        />
                        <Input
                          disabled
                          placeholder={
                            "Yalın Fiyatı: " +
                            numberToMoney(
                              ((100 - values.discount3) / 100) *
                                ((100 - values.discount2) / 100) *
                                ((100 - values.discount1) / 100) *
                                values.price *
                                values.count -
                                values.totalDiscount +
                                floatParser(values.kataForez) * values.count +
                                floatParser(values.kumasFarki) * values.count,
                              " TL"
                            )
                          }
                          className={styles.input}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSide}>
                    <div className={styles.uploadRight}>
                      <Upload
                        defaultFileList={initials.files}
                        {...props}
                        onRemove={handleRemove}
                      >
                        <Button icon={<AiOutlineUpload />}>
                          <span style={{ paddingLeft: 15 }}>Dosya Ekle</span>
                        </Button>
                      </Upload>
                    </div>
                    <div className={styles.addToCart}>
                      <Button
                        className={styles.button}
                        onClick={() => {
                          formikRef.current.handleSubmit();
                        }}
                      >
                        Teklife Ekle
                      </Button>
                    </div>
                  </div>
                </div>

                {isViewerOpen && (
                  <ImageViewer
                    src={[selectedProduct.images[0].url]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={setIsViewerOpen}
                  />
                )}
                {loading ? (
                  <div>Konfigürasyonlar yükleniyor...</div>
                ) : (
                  <div className={styles.configuration}>
                    <div className={styles.container}>
                      <div className={styles.left}>
                        <div className={styles.element}>
                          <label className={styles.label}>Ağaç Tipi : </label>
                          <Select
                            value={combinationInner.agactipi}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setSpecs({ ...specs, agactipi: value });
                            }}
                            showSearch={true}
                            className={styles.select}
                          >
                            {MainStore.agactip[0]
                              ? MainStore.agactip.map((conf, i) => {
                                  if (conf)
                                    return (
                                      <Option key={i} value={conf}>
                                        {conf}
                                      </Option>
                                    );
                                })
                              : null}
                          </Select>
                        </div>
                        <div className={styles.element}>
                          <label className={styles.label}>
                            Boya Rengi 1 :{" "}
                          </label>
                          <Select
                            value={specs.boya1}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setSpecs({ ...specs, boya1: value });
                            }}
                            showSearch={true}
                            className={styles.select}
                          >
                            {MainStore.boya1[0]
                              ? MainStore.boya1.map((conf, i) => {
                                  if (conf)
                                    return (
                                      <Option key={i} value={conf}>
                                        {conf}
                                      </Option>
                                    );
                                })
                              : null}
                          </Select>
                        </div>
                        <div className={styles.element}>
                          <label className={styles.label}>
                            Boya Rengi 2 :{" "}
                          </label>
                          <Select
                            value={specs.boya2}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setSpecs({ ...specs, boya2: value });
                            }}
                            showSearch={true}
                            className={styles.select}
                          >
                            {MainStore.boya2[0]
                              ? MainStore.boya2.map((conf, i) => {
                                  if (conf)
                                    return (
                                      <Option key={i} value={conf}>
                                        {conf}
                                      </Option>
                                    );
                                })
                              : null}
                          </Select>
                        </div>
                        <div className={styles.element}>
                          <label className={styles.label}>Logo : </label>
                          <Select
                            value={"LETA"}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              setSpecs({ ...specs, logo: value });
                            }}
                            disabled={true}
                            showSearch={true}
                            defaultValue={"LETA"}
                            className={styles.select}
                          >
                            {MainStore.logo[0]
                              ? MainStore.logo.map((conf, i) => {
                                  if (conf)
                                    return (
                                      <Option key={i} value={conf}>
                                        {conf}
                                      </Option>
                                    );
                                })
                              : null}
                          </Select>
                        </div>
                        <div className={styles.element}>
                          <label className={styles.label}>
                            Boya Rengi 3 :{" "}
                          </label>
                          <Select
                            value={specs.boya3}
                            onChange={(value) => {
                              setSpecs({ ...specs, boya3: value });
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch={true}
                            className={styles.select}
                          >
                            {MainStore.boya3[0]
                              ? MainStore.boya3.map((conf, i) => {
                                  if (conf)
                                    return (
                                      <Option key={i} value={conf}>
                                        {conf}
                                      </Option>
                                    );
                                })
                              : null}
                          </Select>
                        </div>
                      </div>
                      <>
                        <div className={styles.right}>
                          <div className={[styles.element]}>
                            <label className={styles.label}>
                              Kombinasyonlar :{" "}
                            </label>
                            <Select
                              value={combinationData}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              showSearch={true}
                              onChange={(value) => {
                                setCombinationData(value);
                                setCombinationInner({});
                              }}
                              className={styles.select}
                            >
                              {configuration
                                ? configuration.specs
                                  ? Object.entries(configuration?.specs)?.map(
                                      (spec, i) => {
                                        return (
                                          <Option key={i} value={spec[0]}>
                                            {spec[0]}
                                          </Option>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </Select>
                          </div>
                          <Button
                            onClick={() => {
                              setVisibleKumasSec(!visibleKumasSec);
                            }}
                            style={{ marginLeft: 15 }}
                          >
                            Kumaş sınıfı ve kategori seç
                          </Button>

                          {configuration
                            ? MainStore.kumas[0]
                              ? Object.entries(configuration?.specs).map(
                                  (spec, inx) => {
                                    if (spec[0] === combinationData)
                                      return spec[1].map((realspec, i) => {
                                        return (
                                          <div
                                            key={i}
                                            className={styles.element}
                                          >
                                            <label className={styles.label}>
                                              {realspec}
                                            </label>

                                            <Select
                                              style={{ maxWidth: 250 }}
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                              onChange={(value) => {
                                                setCombinationInner({
                                                  ...combinationInner,
                                                  ["c" + i]: {
                                                    label: realspec,
                                                    value: value,
                                                    name: MainStore?.kumas?.find(
                                                      (data) =>
                                                        data.stkno === value
                                                    ).stokadi,
                                                  },
                                                });
                                              }}
                                              value={
                                                typeof combinationData !==
                                                "undefined"
                                                  ? combinationData["c" + i]
                                                      ?.value
                                                  : ""
                                              }
                                              showSearch={true}
                                              className={styles.select}
                                            >
                                              {MainStore.kumas
                                                ? MainStore.kumas[0]
                                                  ? Object.entries(
                                                      MainStore.kumas
                                                    )?.map((spec, i) => {
                                                      return (
                                                        <Option
                                                          key={i}
                                                          value={spec[1].stkno}
                                                        >
                                                          {spec[1].stokadi}
                                                        </Option>
                                                      );
                                                    })
                                                  : null
                                                : null}
                                            </Select>
                                          </div>
                                        );
                                      });
                                  }
                                )
                              : null
                            : null}
                        </div>
                      </>

                      {visibleKumasSec && (
                        <div
                          style={{
                            width: 350,
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "row",
                            overflowX: "hidden",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "30%",
                              height: 200,
                              borderRight: "1px solid #ccc",
                              marginRight: 20,
                            }}
                          >
                            <h4>KATEGORİ</h4>
                            {specificField(
                              MainStore.kumasKategoriler,
                              "kategori"
                            ).map((kategori, i) => {
                              return (
                                <label
                                  onClick={() => {
                                    setKumasOzellikleri({
                                      ...kumasOzellikleri,
                                      kumasKat: kategori,
                                    });
                                  }}
                                  key={i}
                                  style={{
                                    width: 60,
                                    padding: 5,
                                    fontWeight: 600,
                                    fontSize: 16,
                                    cursor: "pointer",
                                    backgroundColor:
                                      kumasOzellikleri.kumasKat === kategori
                                        ? "#ccc"
                                        : "",
                                  }}
                                >
                                  {kategori}
                                </label>
                              );
                            })}
                          </div>
                          {specificField(
                            MainStore.kumasKategoriler,
                            "grup"
                          ).map((grup, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "35%",
                                }}
                              >
                                <h4>{grup.toUpperCase()}</h4>

                                {specificField(
                                  MainStore.kumasKategoriler,
                                  "tipi"
                                ).map((tip, i) => {
                                  return (
                                    <label
                                      onClick={() => {
                                        setKumasOzellikleri({
                                          ...kumasOzellikleri,
                                          kumasTip: tip,
                                          kumasGrup: grup,
                                        });
                                      }}
                                      key={i}
                                      style={{
                                        width: 60,
                                        padding: 5,
                                        fontWeight: 600,
                                        fontSize: 16,
                                        cursor: "pointer",
                                        backgroundColor:
                                          kumasOzellikleri.kumasTip === tip &&
                                          kumasOzellikleri.kumasGrup === grup
                                            ? "#ccc"
                                            : "",
                                      }}
                                    >
                                      {tip}
                                    </label>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Formik>
        </Modal>
      );
    }
  )
);

export default ProductImageModal;
