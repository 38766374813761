import React, { useState } from "react";
import { Modal, Input, Button, Select, message } from "antd";
import styles from "./userconfiguration.module.css";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import APIKit from "../../shared/APIKit";
import { inject, observer } from "mobx-react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import ImageChange from "./components/ImageChange";

const UserConfiguration = inject("MainStore")(
  observer(({ isOpen, setIsOpen, MainStore }) => {
    const [malls, setMalls] = React.useState([]);
    const [selectedMall, setSelectedMall] = React.useState(
      MainStore.auth.user.magaza
    );
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const [selectedGorev, setSelectedGorev] = React.useState("plasiyer");
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imzaAyarlari, setImzaAyarlari] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userInfo, setUserInfo] = React.useState({
      username: "",
      password: "",
    });
    const [passwordChange, setPasswordChange] = React.useState({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    });
    const { TextArea } = Input;

    const createUser = () => {
      if (
        userInfo.username &&
        userInfo.password &&
        selectedGorev &&
        selectedMall
      )
        APIKit.post("api/user/create", {
          username: userInfo.username,
          password: userInfo.password,
          magaza: selectedMall,
          gorev: selectedGorev,
        })
          .then((res) => {
            setUserInfo({
              username: "",
              password: "",
            });
            message.success("Yeni Hesap Oluşturuldu");
          })
          .catch((err) => {
            message.error("Bir Hata Oluştu");
          });
      else message.error("Alanlar doldurulmalı!");
    };

    const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      setIsGridReady(true);

      params.api.onFilterChanged();
    };

    const onSelectionChanged = (params) => {
      if (isGridReady && gridColumnApi) {
        let selectedNodes = gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
      }
    };

    const gridOptions = {
      pagination: false,
    };

    const changePassword = () => {
      if (passwordChange.newPassword === passwordChange.newPasswordRepeat) {
        APIKit.post("api/user/changePassword", {
          oldPassword: passwordChange.oldPassword,
          newPassword: passwordChange.newPassword,
        })
          .then((res) => {
            setPasswordChange({
              oldPassword: "",
              newPassword: "",
              newPasswordRepeat: "",
            });
            message.success("Şifre değiştirildi.");
          })
          .catch((err) => {
            message.error(err.response.data);
          });
      } else message.error("Yeni şifreler uyuşmuyor!");
    };

    React.useEffect(() => {
      APIKit.get("api/mall").then(({ data }) => {
        setMalls(data);
        if (MainStore.auth.user.gorev === "admin") {
          APIKit.get("api/user").then(({ data }) => {
            setUsers(data);
            setLoading(false);
          });
        }
      });
    }, []);

    const onRowDoubleClicked = (user) => {
      setSelectedUser(user.data);
      setImzaAyarlari(true);
    };

    const tableitems = [
      {
        label: "Id",
        value: "_id",
        width: 150,
      },
      {
        label: "Kullanıcı Adı",
        value: "username",
        width: 160,
      },
      {
        label: "Şifre",
        value: "password",
        width: 150,
      },
      {
        label: "Mağaza",
        value: "magaza",
        width: 150,
      },
      {
        label: "Görev",
        value: "gorev",
        width: 100,
      },
    ];

    const onCellEditing = (data) => {
      APIKit.post("api/user/update", {
        user: data.data,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Modal
        title="Kullanıcı İşlemleri"
        visible={isOpen}
        width={
          MainStore.auth.user.gorev === "Müdür" ||
          MainStore.auth.user.gorev === "admin"
            ? 800
            : 400
        }
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <div className={styles.container}>
          <div
            className={styles.left}
            style={{
              width:
                MainStore.auth.user.gorev === "admin" ||
                MainStore.auth.user.gorev === "Müdür"
                  ? "50%"
                  : "100%",
            }}
          >
            <h4>Şifre Değiştir</h4>
            <Input
              className={styles.input}
              disabled={true}
              placeholder={MainStore.auth.user.username}
            />
            <Input
              value={passwordChange.oldPassword}
              type="password"
              onChange={(e) =>
                setPasswordChange({
                  ...passwordChange,
                  oldPassword: e.target.value,
                })
              }
              className={styles.input}
              placeholder={"Eski şifre"}
            />
            <Input
              value={passwordChange.newPassword}
              type="password"
              onChange={(e) =>
                setPasswordChange({
                  ...passwordChange,
                  newPassword: e.target.value,
                })
              }
              className={styles.input}
              placeholder={"Yeni şifre"}
            />
            <Input
              value={passwordChange.newPasswordRepeat}
              type="password"
              onChange={(e) =>
                setPasswordChange({
                  ...passwordChange,
                  newPasswordRepeat: e.target.value,
                })
              }
              className={styles.input}
              placeholder={"Yeni şifre (tekrar)"}
            />
            <Button onClick={() => changePassword()} className={styles.button}>
              Şifreyi Değiştir
            </Button>
          </div>
          <div
            className={styles.right}
            style={{
              display:
                MainStore.auth.user.gorev === "admin" ||
                MainStore.auth.user.gorev === "Müdür"
                  ? "flex"
                  : "none",
            }}
          >
            <h4>Kullanıcı Oluştur</h4>
            <Input
              value={userInfo.username}
              onChange={(e) =>
                setUserInfo({ ...userInfo, username: e.target.value })
              }
              className={styles.input}
              placeholder={"Kullanıcı Adı"}
            ></Input>
            <Input
              value={userInfo.password}
              onChange={(e) =>
                setUserInfo({ ...userInfo, password: e.target.value })
              }
              className={styles.input}
              type="password"
              placeholder={"Şifre"}
            ></Input>
            <Select
              defaultValue={selectedGorev}
              placeholder={"Yetki Seçiniz"}
              className={styles.input}
              onChange={(value) => setSelectedGorev(value)}
            >
              <Select.Option value={"plasiyer"}>Plasiyer</Select.Option>
              <Select.Option value={"Müdür"}>Müdür</Select.Option>
              {MainStore.auth.user.gorev === "admin" ? (
                <Select.Option value={"admin"}>Admin</Select.Option>
              ) : null}
            </Select>
            <Select
              value={selectedMall}
              className={styles.input}
              placeholder={"Mağaza"}
              onChange={(value) => setSelectedMall(value)}
              disabled={MainStore.auth.user.gorev === "Müdür"}
            >
              {malls[0]
                ? malls.map((mall, i) => {
                    return (
                      <Select.Option key={i} value={mall.name}>
                        {mall.name}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
            <Button onClick={() => createUser()} className={styles.button}>
              Yeni Kullanıcı Oluştur
            </Button>
          </div>
        </div>
        {MainStore.auth.user.gorev === "admin" && (
          <div
            style={{
              width: "100%",
              height: 400,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"balls"}
                color={"var(--primary-color)"}
                height={300}
                width={150}
              />
            ) : (
              <div
                id="myGrid"
                className="ag-theme-alpine"
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  modules={[AllCommunityModules]}
                  onGridReady={onGridReady}
                  rowData={users}
                  rowSelection="single"
                  gridOptions={gridOptions}
                  onSelectionChanged={onSelectionChanged}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onCellEditingStopped={onCellEditing}
                  immutableData={false}
                  getRowNodeId={(data) => data._id}
                  defaultColDef={{
                    width: 150,
                    cellStyle: {
                      textAlign: "left",
                      paddingLeft: 5,
                    },
                    sortable: true,
                    filter: true,
                    floatingFilter: true,
                    editable: true,
                    resizable: true,
                    type: "leftAligned",
                  }}
                >
                  {tableitems.map((item, idx) => {
                    return (
                      <AgGridColumn
                        key={idx}
                        headerName={item.label}
                        field={item.value}
                        minWidth={item?.width}
                      />
                    );
                  })}
                </AgGridReact>
              </div>
            )}
          </div>
        )}
        <ImageChange
          isOpen={imzaAyarlari}
          setIsOpen={setImzaAyarlari}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </Modal>
    );
  })
);

export default UserConfiguration;
