import React, { useState } from "react";
import styles from "./newproducts.module.css";

import APIKit from "../../shared/APIKit";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ReactLoading from "react-loading";
import ProductModal from "./ProductModal";
import { FaFirstdraft } from "react-icons/fa";
import { Button, message } from "antd";
import { inject, observer } from "mobx-react";

const NewProducts = inject("MainStore")(
  observer(({ MainStore }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const [isDoubleClicked, setIsDoubleClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newProducts, setNewProducts] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState({});

    const tableitems = [
      {
        label: "Web Stok No",
        value: "webstkno",
        width: 150,
      },
      {
        label: "Stok Adı",
        value: "stokadi",
        width: 360,
      },
      {
        label: "Açıklama",
        value: "aciklama",
        width: 360,
      },
      {
        label: "Oluşturan Kişi",
        value: "whoCreateIt",
        width: 230,
      },
      {
        label: "Oluşturulma Tarihi",
        value: "revizeDate",
        width: 270,
      },
    ];

    const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      setIsGridReady(true);
    };

    const onSelectionChanged = (params) => {
      if (isGridReady && gridColumnApi) {
        let selectedNodes = gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
        setSelectedProduct(selectedData[0]);
      }
    };

    const gridOptions = {
      pagination: false,
    };

    const onRowDoubleClicked = (data) => {
      setSelectedProduct(data.data);
      setIsOpen(true);
    };

    const getNewProducts = () => {
      setLoading(true);
      APIKit.get("/api/offer/newproducts")
        .then(({ data }) => {
          setNewProducts(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    React.useEffect(() => {
      getNewProducts();
    }, []);

    if (loading)
      return (
        <div className={styles.loading}>
          <ReactLoading
            type={"balls"}
            color={"var(--background-color)"}
            height={120}
            width={120}
          />
        </div>
      );
    else if (!newProducts[0])
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: 10,
            }}
          >
            <Button onClick={() => getNewProducts()}>Yenile</Button>
          </div>

          <div className={styles.notFound}>
            <FaFirstdraft className={styles.icon} />
            <span>Hiç yeni ürün yok!</span>
          </div>
        </>
      );
    else
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: 10,
            }}
          >
            <Button onClick={() => getNewProducts()}>Yenile</Button>
          </div>
          <div className={styles.maincontainer}>
            <div
              id="myGrid"
              className="ag-theme-alpine"
              style={{ width: "100%", height: "100%" }}
            >
              <AgGridReact
                modules={[AllCommunityModules]}
                onGridReady={onGridReady}
                rowData={newProducts}
                rowSelection="single"
                gridOptions={gridOptions}
                onRowDoubleClicked={onRowDoubleClicked}
                onSelectionChanged={onSelectionChanged}
                immutableData={false}
                getRowNodeId={(data) => data.webstkno}
                defaultColDef={{
                  width: 150,
                  sortable: true,
                  filter: "agTextColumnFilter",
                  floatingFilter: true,
                  editable: false,
                  resizable: true,
                }}
              >
                {tableitems.map((item, index) => {
                  return (
                    <AgGridColumn
                      key={index}
                      headerName={item.label}
                      field={item.value}
                      minWidth={item?.width}
                    />
                  );
                })}
              </AgGridReact>
            </div>
            <ProductModal
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              product={selectedProduct}
              getNewProducts={getNewProducts}
            />
          </div>
        </>
      );
  })
);

export default NewProducts;
