import React from "react";
import styles from "./offercartitem.module.css";
import { Input, DatePicker, Button, Checkbox, Upload, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  AiOutlineUpload,
  AiOutlineLoading,
  AiOutlinePlus,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BiArrowFromTop, BiArrowToTop } from "react-icons/bi";
import {
  GO_DOWN,
  GO_UP,
  REMOVE_FROM_CART,
  REVIZE,
  SET_CART,
  UPDATE_CART_ITEM,
  UPDATE_PRODUCT,
} from "../../actions/types";
import ProductModal from "../productConfigureModal/productModal";
import { numberToMoney } from "../../utils/util";
import S3 from "react-aws-s3";
import APIKit from "../../shared/APIKit";
import s3Config from "../../config/s3Config";
import { saveState } from "../../actions/localstorage";
import { inject, observer } from "mobx-react";
import LoadingOverlay from "react-loading-overlay";

const OfferItem = inject("MainStore")(
  observer(
    ({
      product,
      index,
      setRefresh,
      refresh,
      defaultMoney,
      offerCart,
      MainStore,
    }) => {
      const [isOpen, setIsOpen] = React.useState(false);
      const [error, setError] = React.useState(false);
      const [imageUrl, setImageUrl] = React.useState("");
      const [loading, setLoading] = React.useState(false);
      const [, setItemProps] = React.useState([]);
      const [confLoading, setConfLoading] = React.useState(false);

      const propsImage = {
        customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials,
        }) {
          const config = {
            bucketName: s3Config.bucketName,
            dirName: "leta/images/" + product?.stkno /* optional */,
            region: "eu-central-1",
            accessKeyId: s3Config.accessKeyId,
            secretAccessKey: s3Config.secretAccessKey,
          };

          const ReactS3Client = new S3(config);
          const fileName = Math.floor(Date.now() / 1000) + ".jpg";

          ReactS3Client.uploadFile(file, fileName).then((data) => {
            const itemChanged = {
              ...product,
              images: [
                ...product?.images,
                {
                  uid: data.location,
                  name: fileName,
                  status: "done",
                  url: data.location,
                },
              ],
            };
            setItemProps(itemChanged);
            // updateRedux();
            handleCartItemUpdate(itemChanged);
            setLoading(false);
            setImageUrl(data.location);
            updateRedux();
            message.success("Resim başarıyla yüklendi!");
          });
        },
      };

      function beforeUpload(file) {
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          message.error("Sadece JPG/PNG formatında resim yükleyebilirsiniz!");
        }

        return isJpgOrPng;
      }

      const props = {
        customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials,
        }) {
          const config = {
            bucketName: s3Config.bucketName,
            dirName: "leta/files/" + product?.stkno /* optional */,
            region: "eu-central-1",
            accessKeyId: s3Config.accessKeyId,
            secretAccessKey: s3Config.secretAccessKey,
          };

          const ReactS3Client = new S3(config);
          const fileName = Math.floor(Date.now() / 1000) + ".jpg";

          ReactS3Client.uploadFile(file, fileName)
            .then((data) => {
              const itemChanged = {
                ...product,
                files: [
                  ...product?.files,
                  {
                    uid: data.location,
                    name: fileName,
                    status: "done",
                    url: data.location,
                  },
                ],
              };
              setItemProps(itemChanged);
              // updateRedux();
              handleCartItemUpdate(itemChanged);

              onSuccess(
                {
                  uid: data.location,
                  name: fileName,
                  status: "done",
                  url: data.location,
                },
                product
              );

              setRefresh(!refresh);
              updateRedux();

              message.success("Resim başarıyla yüklendi!");
            })
            .catch((err) => {
              message.error("Resim başarıyla yüklendi!");
            });
        },
      };

      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }

      const updateRedux = () => {
        // MainStore.updateCartItem(itemProps, index);
        // setRefresh(!refresh);
      };

      const handleCartItemUpdate = (itemProp) => {
        MainStore.updateCartItem(itemProp, index);
        setRefresh(!refresh);
      };

      const handleRemove = (item) => {
        var newProduct = [];

        for (var i = 0; i < Object.keys(product?.files).length; i++) {
          if (product?.files[i].url !== item.url) {
            newProduct?.push(product?.files[i]);
          }
        }

        setItemProps({ ...product, files: newProduct });
        updateRedux();
      };

      const handleChangeImage = (info) => {
        if (info.file.status === "uploading") {
          setLoading(true);
          return;
        }
        if (info.file.status === "done") {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
            setLoading(false);
          });
        }
      };

      const uploadButton = (
        <div>
          {loading ? <AiOutlineLoading /> : <AiOutlinePlus />}
          <div style={{ marginTop: 8 }}>Resim Ekle</div>
        </div>
      );

      const floatParser = (data) => {
        if (parseFloat(data)) return parseFloat(data);
        else return 0;
      };

      return (
        <LoadingOverlay
          active={confLoading}
          styles={{}}
          spinner
          text={"Konfigürasyonlar Yükleniyor..."}
        >
          <div className={styles.container}>
            <div className={styles.topbottom}>
              {index !== 0 ? (
                <button
                  onClick={() => {
                    MainStore.upOfferCart(index);
                    setRefresh(!refresh);
                  }}
                >
                  <BiArrowToTop />
                </button>
              ) : null}
              {index !== offerCart.length - 1 ? (
                <button
                  onClick={() => {
                    console.log("works");
                    MainStore.downOfferCart(index);
                    setRefresh(!refresh);
                  }}
                >
                  <BiArrowFromTop />
                </button>
              ) : null}
            </div>
            <div className={styles.left}>
              {error ||
              !product ||
              !product?.hasOwnProperty("images") ||
              !product?.images[0] ? (
                <div className={styles.imageUpload}>
                  <Upload
                    {...propsImage}
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                  >
                    {imageUrl ||
                    (product?.hasOwnProperty("images") &&
                      product?.images[0]) ? (
                      <img
                        src={
                          product?.images[0] ? product?.images[0].url : imageUrl
                        }
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
              ) : product?.hasOwnProperty("images") && product?.images ? (
                product?.images[0] ? (
                  <img
                    onError={() => setError(true)}
                    src={
                      Object.keys(product?.images).length
                        ? product?.images[0].url
                        : ""
                    }
                    className={styles.image}
                  />
                ) : null
              ) : null}

              <div className={styles.plusbottom}>
                <div
                  className={styles.old}
                  onClick={() => {
                    if (product?.count !== 1) {
                      const itemChanged = {
                        ...product,
                        count: product?.count - 1,
                      };
                      setItemProps(itemChanged);
                      // updateRedux();
                      handleCartItemUpdate(itemChanged);
                    } else {
                      MainStore.removeFromCart(index);

                      if (offerCart)
                        if (Object.keys(offerCart).length <= 0)
                          MainStore.setRevize(false);

                      setRefresh(!refresh);
                    }
                  }}
                >
                  -
                </div>
                <span>{product?.count}</span>
                <div
                  className={styles.old}
                  onClick={() => {
                    const itemChanged = {
                      ...product,
                      count: product?.count + 1,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                >
                  +
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.row}>
                <Input
                  disabled={
                    !product?.hasOwnProperty("isNew") && !product?.isNew
                  }
                  value={product?.stokadi}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      stokadi: e.target.value,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  className={styles.longinput}
                  placeholder="Ürün Adı"
                />
                <Input
                  value={"Stok Kodu: " + product?.stkno}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      stkno: e.target.value,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  placeholder={"Stok Kodu"}
                  className={styles.input}
                  disabled={true}
                />
                <Input
                  value={product?.discount1 !== 0 ? product?.discount1 : ""}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      discount1: parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  addonAfter="%"
                  className={styles.input}
                  placeholder="İskonto(1)"
                />
                <Input
                  value={product?.kataForez ? product?.kataForez : ""}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      kataForez: parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  addonAfter={defaultMoney}
                  className={styles.input}
                  placeholder="Kataforez Farkı"
                />
              </div>
              <div className={styles.row}>
                <Input
                  value={product?.ahsapmetal}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      ahsapmetal: e.target.value,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  className={styles.input}
                  placeholder="Ahşap/Metal Detayı"
                />
                <Input
                  value={product?.derikumas}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      derikumas: e.target.value,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  className={styles.input}
                  placeholder="Deri/Kumaş Detayı"
                />
                <Input
                  disabled
                  className={styles.input}
                  style={{
                    opacity: 0,
                    cursor: "default",
                  }}
                  placeholder={
                    "Liste Fiyatı: " + numberToMoney(product?.lstFiyat, "")
                  }
                />
                <Button
                  onClick={() => {
                    MainStore.removeFromCart(index);
                    setRefresh(!refresh);
                  }}
                  className={styles.button}
                >
                  {!MainStore.revize ? "Tekliften Kaldır" : "Revizeden Kaldır"}
                </Button>
                <Input
                  value={product?.discount2 !== 0 ? product?.discount2 : ""}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      discount2: parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  addonAfter="%"
                  className={styles.input}
                  placeholder="İskonto(2)"
                />

                <Input
                  value={product?.kumasFarki ? product?.kumasFarki : ""}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      kumasFarki: parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  addonAfter={defaultMoney}
                  className={styles.input}
                  placeholder="Kumaş Farkı"
                />
              </div>
              <div className={styles.leftSide}>
                <TextArea
                  value={product?.aciklama}
                  onChange={(e) => {
                    const itemChanged = {
                      ...product,
                      aciklama: e.target.value,
                    };
                    setItemProps(itemChanged);
                    // updateRedux();
                    handleCartItemUpdate(itemChanged);
                  }}
                  className={styles.textarea}
                  placeholder={"Açıklama"}
                />
                <div className={styles.col}>
                  <Button
                    onClick={() => setIsOpen(true)}
                    className={styles.button}
                  >
                    Özelleştir
                  </Button>
                  <Input
                    value={product?.price !== 0 ? product?.price : ""}
                    onChange={(e) => {
                      const itemChanged = {
                        ...product,
                        price: parseFloat(e.target.value)
                          ? parseFloat(e.target.value)
                          : 0,
                      };
                      setItemProps(itemChanged);
                      // updateRedux();
                      handleCartItemUpdate(itemChanged);
                    }}
                    placeholder={"Adet Fiyatı"}
                    className={styles.input}
                  />
                </div>
                <div className={styles.right}>
                  <div className={styles.row}>
                    <Input
                      value={product?.discount3 !== 0 ? product?.discount3 : ""}
                      addonAfter="%"
                      onChange={(e) => {
                        const itemChanged = {
                          ...product,
                          discount3: parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0,
                        };
                        setItemProps(itemChanged);
                        // updateRedux();
                        handleCartItemUpdate(itemChanged);
                      }}
                      className={styles.input}
                      placeholder="İskonto(3)"
                    />

                    <Input
                      value={product?.kdv !== 0 ? product?.kdv : ""}
                      onChange={(e) => {
                        const itemChanged = {
                          ...product,
                          kdv: parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0,
                        };
                        setItemProps(itemChanged);
                        // updateRedux();
                        handleCartItemUpdate(itemChanged);
                      }}
                      addonAfter="%"
                      className={styles.input}
                      placeholder="KDV"
                    />
                  </div>
                  <div className={styles.row}>
                    <Input
                      value={
                        product?.totalDiscount !== 0
                          ? product?.totalDiscount
                          : ""
                      }
                      onChange={(e) => {
                        const itemChanged = {
                          ...product,
                          totalDiscount: parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0,
                        };
                        setItemProps(itemChanged);
                        // updateRedux();
                        handleCartItemUpdate(itemChanged);
                      }}
                      addonAfter={defaultMoney}
                      className={styles.input}
                      placeholder="Kalem İskonto"
                    />

                    <Input
                      className={styles.input}
                      disabled={true}
                      value={String(
                        numberToMoney(
                          (((100 - product?.discount3) / 100) *
                            ((100 - product?.discount2) / 100) *
                            ((100 - product?.discount1) / 100) *
                            product?.price *
                            product?.count -
                            product?.totalDiscount +
                            floatParser(product?.kataForez) * product?.count +
                            floatParser(product?.kumasFarki) * product?.count) *
                            (floatParser(product?.kdv) / 100) +
                            (((100 - product?.discount3) / 100) *
                              ((100 - product?.discount2) / 100) *
                              ((100 - product?.discount1) / 100) *
                              product?.price *
                              product?.count -
                              product?.totalDiscount +
                              floatParser(product?.kataForez) * product?.count +
                              floatParser(product?.kumasFarki) *
                                product?.count),
                          ""
                        )
                      )}
                      addonAfter={defaultMoney}
                      placeholder="Toplam Fiyat"
                    />
                    <Input
                      disabled
                      placeholder={
                        "Yalın Fiyatı: " +
                        numberToMoney(
                          ((100 - product?.discount3) / 100) *
                            ((100 - product?.discount2) / 100) *
                            ((100 - product?.discount1) / 100) *
                            product?.price *
                            product?.count -
                            product?.totalDiscount +
                            floatParser(product?.kataForez) * product?.count +
                            floatParser(product?.kumasFarki) * product?.count,
                          " TL"
                        )
                      }
                      className={styles.input}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.uploadRight}>
              <Upload
                defaultFileList={product?.files}
                {...props}
                onRemove={handleRemove}
              >
                <Button icon={<AiOutlineUpload />}>
                  <span style={{ paddingLeft: 15 }}>Dosya Ekle</span>
                </Button>
              </Upload>
            </div>
            <ProductModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              product={product}
              handleCartItemUpdate={handleCartItemUpdate}
              updateRedux={updateRedux}
              setItemProps={setItemProps}
              itemProps={product}
              confLoading={confLoading}
              setConfLoading={setConfLoading}
            />
          </div>
        </LoadingOverlay>
      );
    }
  )
);
export default OfferItem;
