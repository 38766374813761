import React from "react";
import styles from "./revizedetails.module.css";
import { Modal } from "antd";
import { numberToMoney } from "../../utils/util";

const RevizeDetails = ({
  isOpen,
  setIsOpen,
  selectedOffer,
  currenyUnit,
  withDiscountPrice,
  withoutDiscountPrice,
  discount,
  revize,
  revizeOneBefore,
  floatParser,
}) => {
  const [explanation, setExplanation] = React.useState("");

  const correctColor = "rgba(113, 214, 45,0.5)";
  const errorColor = correctColor; //"rgba(255, 0, 0,0.3)";

  return (
    <Modal
      title="Revize Detayları"
      visible={isOpen}
      width={1000}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <div className={styles.container}>
        {revize.revizeCount > 0 ? (
          <div
            className={styles.top}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <span>
              Revize Sebebi:{" "}
              {
                selectedOffer.offers[
                  Object.keys(selectedOffer.offers).length - 1
                ][0].revizeExplanation
              }
            </span>
            <span>
              Revize eden :{" "}
              {
                selectedOffer.offers[
                  Object.keys(selectedOffer.offers).length - 1
                ][0].whoDidRevize
              }
            </span>
          </div>
        ) : null}
        <div className={styles.mainContainer}>
          {revizeOneBefore[0]
            ? revize.map((offer, i) => {
                return (
                  <div key={i} className={styles.offerContainer}>
                    <div className={styles.imgContainer}>
                      <img src={offer.images[0]?.url} />
                    </div>
                    <div className={styles.offerItem}>
                      <div className={styles.row}>
                        <span>
                          {offer?.stkno ? "Stok Kodu: " : "Web Stok Kodu: "}
                          {offer?.stkno ? offer?.stkno : offer?.webstkno}
                        </span>
                        <span>Stok Adı: {offer.stokadi}</span>
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.derikumas !==
                                revizeOneBefore[i]?.derikumas &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.derikumas !== revizeOneBefore[i]?.derikumas)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Deri/Kumaş Detayı: {offer.derikumas}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.ahsapmetal !==
                                revizeOneBefore[i].ahsapmetal &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.ahsapmetal !==
                                revizeOneBefore[i].ahsapmetal)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Ahşap/Metal Detayı: {offer.ahsapmetal}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.agactipi !== revizeOneBefore[i].agactipi &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.agactipi !== revizeOneBefore[i].agactipi)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Ağaç Tipi: {offer.agactipi}
                        </span>
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.combination !==
                                revizeOneBefore[i].combination &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.combination !==
                                revizeOneBefore[i].combination)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Seçili Kombinasyon:{" "}
                          {offer.combination ? offer.combination : "Yok"}
                        </span>

                        {offer.combinationData !== undefined
                          ? Object.values(offer.combinationData).map(
                              (comb, index) => {
                                return (
                                  <span
                                    key={index}
                                    style={
                                      ({ minWidth: 230 },
                                      Object.values(
                                        revizeOneBefore[i]?.combinationData
                                      )[index]?.value !== comb.value
                                        ? {
                                            backgroundColor: correctColor,
                                          }
                                        : null)
                                    }
                                  >
                                    {comb.label}:{" "}
                                    {comb["name"] ? comb.name : comb.value}
                                  </span>
                                );
                              }
                            )
                          : null}
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.aciklama !== revizeOneBefore[i].aciklama &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.aciklama !== revizeOneBefore[i].aciklama)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Teknik Açıklama: {offer.aciklama}
                        </span>
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.boya1 !== revizeOneBefore[i].boya1 &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.boya1 !== revizeOneBefore[i].boya1)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Boya1: {offer.boya1}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.boya2 !== revizeOneBefore[i].boya2 &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.boya2 !== revizeOneBefore[i].boya2)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Boya2: {offer.boya2}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.boya3 !== revizeOneBefore[i].boya3 &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.boya3 !== revizeOneBefore[i].boya3)
                              ? { backgroundColor: correctColor }
                              : null
                          }
                        >
                          Boya3: {offer.boya3}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.kataForez !==
                                revizeOneBefore[i].kataForez &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.kataForez !== revizeOneBefore[i].kataForez)
                              ? offer.kataForez > revizeOneBefore[i].kataForez
                                ? { backgroundColor: correctColor }
                                : offer.kataForez < revizeOneBefore[i].kataForez
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          Kataforez Farkı:{" "}
                          {numberToMoney(offer.kataForez, currenyUnit)}
                        </span>

                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.kumasFarki !==
                                revizeOneBefore[i].kumasFarki &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.kumasFarki !==
                                revizeOneBefore[i].kumasFarki)
                              ? offer.kumasFarki > revizeOneBefore[i].kumasFarki
                                ? { backgroundColor: correctColor }
                                : offer.kumasFarki <
                                  revizeOneBefore[i].kumasFarki
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          Kumaş Farkı:{" "}
                          {numberToMoney(offer.kumasFarki, currenyUnit)}
                        </span>
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.price !== revizeOneBefore[i].price &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.price !== revizeOneBefore[i].price)
                              ? offer.price > revizeOneBefore[i].price
                                ? { backgroundColor: correctColor }
                                : offer.price < revizeOneBefore[i].price
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          Fiyat: {numberToMoney(offer.price, currenyUnit)}
                        </span>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.count !== revizeOneBefore[i].count &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.count !== revizeOneBefore[i].count)
                              ? offer.count > revizeOneBefore[i].count
                                ? { backgroundColor: correctColor }
                                : offer.count < revizeOneBefore[i].count
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          Adet: {offer.count}
                        </span>

                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.kdv !== revizeOneBefore[i].kdv &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.kdv !== revizeOneBefore[i].kdv)
                              ? offer.kdv > revizeOneBefore[i].kdv
                                ? { backgroundColor: correctColor }
                                : offer.kdv < revizeOneBefore[i].kdv
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          KDV: {offer.kdv + "%"}
                        </span>
                        {offer.discount1 ? (
                          <span
                            style={
                              (revizeOneBefore[i]?.stkno === offer.stkno &&
                                offer.discount1 !==
                                  revizeOneBefore[i].discount1 &&
                                offer.stkno) ||
                              (revizeOneBefore[i]?.webstkno ===
                                offer.webstkno &&
                                offer.webstkno &&
                                offer.discount1 !==
                                  revizeOneBefore[i].discount1)
                                ? offer.discount1 > revizeOneBefore[i].discount1
                                  ? { backgroundColor: correctColor }
                                  : offer.discount1 <
                                    revizeOneBefore[i].discount1
                                  ? { backgroundColor: errorColor }
                                  : null
                                : null
                            }
                          >
                            İsk1: {offer.discount1 + "%"}
                          </span>
                        ) : null}

                        {offer.discount2 ? (
                          <span
                            style={
                              (revizeOneBefore[i]?.stkno === offer.stkno &&
                                offer.discount2 !==
                                  revizeOneBefore[i].discount2 &&
                                offer.stkno) ||
                              (revizeOneBefore[i]?.webstkno ===
                                offer.webstkno &&
                                offer.webstkno &&
                                offer.discount2 !==
                                  revizeOneBefore[i].discount2)
                                ? offer.discount2 > revizeOneBefore[i].discount2
                                  ? { backgroundColor: correctColor }
                                  : offer.discount2 <
                                    revizeOneBefore[i].discount2
                                  ? { backgroundColor: errorColor }
                                  : null
                                : null
                            }
                          >
                            İsk2: {offer.discount2 + "%"}
                          </span>
                        ) : null}
                        {offer.discount3 ? (
                          <span
                            style={
                              (revizeOneBefore[i]?.stkno === offer.stkno &&
                                offer.discount3 !==
                                  revizeOneBefore[i].discount3 &&
                                offer.stkno) ||
                              (revizeOneBefore[i]?.webstkno ===
                                offer.webstkno &&
                                offer.webstkno &&
                                offer.discount3 !==
                                  revizeOneBefore[i].discount3)
                                ? offer.discount3 > revizeOneBefore[i].discount3
                                  ? { backgroundColor: correctColor }
                                  : offer.discount3 <
                                    revizeOneBefore[i].discount3
                                  ? { backgroundColor: errorColor }
                                  : null
                                : null
                            }
                          >
                            İsk3: {offer.discount3 + "%"}
                          </span>
                        ) : null}
                      </div>
                      <div className={styles.row}>
                        <span
                          style={
                            (revizeOneBefore[i]?.stkno === offer.stkno &&
                              offer.price * offer.count !==
                                revizeOneBefore[i].price *
                                  revizeOneBefore[i].count &&
                              offer.stkno) ||
                            (revizeOneBefore[i]?.webstkno === offer.webstkno &&
                              offer.webstkno &&
                              offer.price * offer.count !==
                                revizeOneBefore[i].price *
                                  revizeOneBefore[i].count)
                              ? offer.price * offer.count >
                                revizeOneBefore[i].price *
                                  revizeOneBefore[i].count
                                ? { backgroundColor: correctColor }
                                : offer.price * offer.count <
                                  revizeOneBefore[i].price *
                                    revizeOneBefore[i].count
                                ? { backgroundColor: errorColor }
                                : null
                              : null
                          }
                        >
                          Genel Fiyat:{" "}
                          {numberToMoney(
                            offer.price * offer.count,
                            currenyUnit
                          )}
                        </span>
                        <span>
                          Toplam İskonto:{" "}
                          {offer.price * offer.count -
                            ((((100 - offer.discount3) / 100) *
                              ((100 - offer.discount2) / 100) *
                              ((100 - offer.discount1) / 100) *
                              offer.price *
                              offer.count -
                              offer.totalDiscount +
                              floatParser(offer.kataForez) * offer.count +
                              floatParser(offer.kumasFarki) * offer.count) *
                              (floatParser(offer.kdv) / 100) +
                              (((100 - offer.discount3) / 100) *
                                ((100 - offer.discount2) / 100) *
                                ((100 - offer.discount1) / 100) *
                                offer.price *
                                offer.count -
                                offer.totalDiscount +
                                floatParser(offer.kataForez) * offer.count +
                                floatParser(offer.kumasFarki) * offer.count)) >
                          0
                            ? numberToMoney(
                                offer.price * offer.count -
                                  ((((100 - offer.discount3) / 100) *
                                    ((100 - offer.discount2) / 100) *
                                    ((100 - offer.discount1) / 100) *
                                    offer.price *
                                    offer.count -
                                    offer.totalDiscount +
                                    floatParser(offer.kataForez) * offer.count +
                                    floatParser(offer.kumasFarki) *
                                      offer.count) *
                                    (floatParser(offer.kdv) / 100) +
                                    (((100 - offer.discount3) / 100) *
                                      ((100 - offer.discount2) / 100) *
                                      ((100 - offer.discount1) / 100) *
                                      offer.price *
                                      offer.count -
                                      offer.totalDiscount +
                                      floatParser(offer.kataForez) *
                                        offer.count +
                                      floatParser(offer.kumasFarki) *
                                        offer.count)),
                                currenyUnit
                              )
                            : 0 + currenyUnit}
                        </span>
                        <span>
                          Genel Toplam:{" "}
                          {numberToMoney(
                            (((100 - offer.discount3) / 100) *
                              ((100 - offer.discount2) / 100) *
                              ((100 - offer.discount1) / 100) *
                              offer.price *
                              offer.count -
                              offer.totalDiscount +
                              floatParser(offer.kataForez) * offer.count +
                              floatParser(offer.kumasFarki) * offer.count) *
                              (floatParser(offer.kdv) / 100) +
                              (((100 - offer.discount3) / 100) *
                                ((100 - offer.discount2) / 100) *
                                ((100 - offer.discount1) / 100) *
                                offer.price *
                                offer.count -
                                offer.totalDiscount +
                                floatParser(offer.kataForez) * offer.count +
                                floatParser(offer.kumasFarki) * offer.count),
                            currenyUnit
                          )}
                        </span>
                      </div>
                      <div className={styles.row}>
                        {offer.files[0] ? (
                          <div className={styles.attachment}>
                            Ek Dosyalar
                            <div className={styles.buttons}>
                              {offer.files.map((file, i) => {
                                return (
                                  <a key={i} href={file.url} target="_blank">
                                    Ek {i + 1}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            : revize.map((offer, i) => {
                return (
                  <div key={i} className={styles.offerContainer}>
                    <div className={styles.imgContainer}>
                      <img src={offer.images[0]?.url} />
                    </div>
                    <div className={styles.offerItem}>
                      <div className={styles.row}>
                        <span>
                          {offer.stkno ? "Stok Kodu: " : "Web Stok Kodu: "}
                          {offer.stkno ? offer.stkno : offer.webstkno}
                        </span>
                        <span>Stok Adı: {offer.stokadi}</span>
                      </div>
                      <div className={styles.row}>
                        <span>Deri/Kumaş Detayı: {offer.derikumas}</span>
                        <span>Ahşap/Metal Detayı: {offer.ahsapmetal}</span>
                        <span>Ağaç Tipi: {offer.agactipi}</span>
                      </div>
                      <div className={styles.row}>
                        <span>
                          Seçili Kombinasyon:{" "}
                          {offer.combination ? offer.combination : "Yok"}
                        </span>

                        {offer.combinationData !== undefined
                          ? Object.values(offer.combinationData).map(
                              (comb, i) => {
                                return (
                                  <span key={i} style={{ minWidth: 230 }}>
                                    {comb.label}: {comb.value}
                                  </span>
                                );
                              }
                            )
                          : null}
                      </div>
                      <div className={styles.row}>
                        <span>Teknik Açıklama: {offer.aciklama}</span>
                      </div>
                      <div className={styles.row}>
                        <span>Boya1: {offer.boya1}</span>
                        <span>Boya2: {offer.boya2}</span>
                        <span>Boya3: {offer.boya3}</span>
                        <span>
                          Kataforez Farkı:{" "}
                          {numberToMoney(offer.kataForez, currenyUnit)}
                        </span>
                        <span>
                          Kumaş Farkı:{" "}
                          {numberToMoney(offer.kumasFarki, currenyUnit)}
                        </span>
                      </div>
                      <div className={styles.row}>
                        <span>
                          Fiyat: {numberToMoney(offer.price, currenyUnit)}
                        </span>
                        <span>Adet: {offer.count}</span>
                        <span>
                          Fiyat: {numberToMoney(offer.price, currenyUnit)}
                        </span>
                        <span>KDV: {offer.kdv + "%"}</span>
                        <span>İsk1: {offer.discount1 + "%"}</span>
                        <span>İsk2: {offer.discount2 + "%"}</span>
                        <span>İsk3: {offer.discount3 + "%"}</span>
                      </div>
                      <div className={styles.row}>
                        <span>
                          KDV Dahil Fiyat:{" "}
                          {numberToMoney(
                            offer.price * offer.count,
                            currenyUnit
                          )}
                        </span>
                        <span>
                          Toplam İskonto:{" "}
                          {offer.price * offer.count -
                            ((((100 - offer.discount3) / 100) *
                              ((100 - offer.discount2) / 100) *
                              ((100 - offer.discount1) / 100) *
                              offer.price *
                              offer.count -
                              offer.totalDiscount +
                              offer.kataForez +
                              offer.kumasFarki) *
                              (offer.kdv / 100) +
                              (((100 - offer.discount3) / 100) *
                                ((100 - offer.discount2) / 100) *
                                ((100 - offer.discount1) / 100) *
                                offer.price *
                                offer.count -
                                offer.totalDiscount +
                                offer.kataForez +
                                offer.kumasFarki)) >
                          0
                            ? numberToMoney(
                                offer.price * offer.count -
                                  ((((100 - offer.discount3) / 100) *
                                    ((100 - offer.discount2) / 100) *
                                    ((100 - offer.discount1) / 100) *
                                    offer.price *
                                    offer.count -
                                    offer.totalDiscount +
                                    offer.kataForez +
                                    offer.kumasFarki) *
                                    (offer.kdv / 100) +
                                    (((100 - offer.discount3) / 100) *
                                      ((100 - offer.discount2) / 100) *
                                      ((100 - offer.discount1) / 100) *
                                      offer.price *
                                      offer.count -
                                      offer.totalDiscount +
                                      offer.kataForez +
                                      offer.kumasFarki)),
                                currenyUnit
                              )
                            : 0 + currenyUnit}
                        </span>
                        <span>
                          Genel Toplam:{" "}
                          {numberToMoney(
                            (((100 - offer.discount3) / 100) *
                              ((100 - offer.discount2) / 100) *
                              ((100 - offer.discount1) / 100) *
                              offer.price *
                              offer.count -
                              offer.totalDiscount +
                              offer.kataForez +
                              offer.kumasFarki) *
                              (offer.kdv / 100) +
                              (((100 - offer.discount3) / 100) *
                                ((100 - offer.discount2) / 100) *
                                ((100 - offer.discount1) / 100) *
                                offer.price *
                                offer.count -
                                offer.totalDiscount +
                                offer.kataForez +
                                offer.kumasFarki),
                            currenyUnit
                          )}
                        </span>
                      </div>
                      <div className={styles.row}>
                        {offer.files[0] ? (
                          <div className={styles.attachment}>
                            Ek Dosyalar
                            <div className={styles.buttons}>
                              {offer.files.map((file, i) => {
                                return (
                                  <a key={i} href={file.url} target="_blank">
                                    Ek {i + 1}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
        <div className={styles.totalBar}>
          <div className={styles.row}>
            <span className={styles.price}>
              Kdv Dahil Fiyat:{" "}
              {numberToMoney(withoutDiscountPrice, currenyUnit)}
            </span>
            <span className={styles.price}>
              Toplam İskonto:{" "}
              {numberToMoney(discount ? discount : 0, currenyUnit)}
            </span>
            <span className={styles.price}>
              Toplam Fiyat: {numberToMoney(withDiscountPrice, currenyUnit)}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RevizeDetails;
