import React from "react";
import { Modal, Input, Button, message } from "antd";
import styles from "./newproducts.module.css";
import APIKit from "../../shared/APIKit";

function ProductModal({ isOpen, setIsOpen, product, getNewProducts }) {
  const [stkno, setStkno] = React.useState("");
  const { TextArea } = Input;

  const matchStkno = () => {
    APIKit.post("/api/product/matchnewproduct", {
      webstkno: product.webstkno,
      stkno: stkno,
    })
      .then((res) => {
        setIsOpen(false);
        getNewProducts();
        message.success("Ürün başarıyla eşleştirildi!");
      })
      .catch((err) => {
        message.error(err.response.data);
      });
  };

  if (product)
    return (
      <Modal
        title="Yeni Ürün Detayları"
        visible={isOpen}
        width={700}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <div className={styles.mainContainer}>
          {product.images ? (
            <img
              className={styles.img}
              src={product.images[0] ? product.images[0].url : null}
            />
          ) : null}

          <h3>Web Stok No: {product.webstkno}</h3>
          <h3>Ürün Adı: {product.stokadi}</h3>

          {product.files ? (
            product.files[0] ? (
              <>
                <h2>Ek Dosyalar</h2>

                <div className={styles.row}>
                  {Object.values(product.files).map((file, i) => {
                    return (
                      <a href={file.url} target="_blank">
                        Ek {i + 1}
                      </a>
                    );
                  })}
                </div>
              </>
            ) : null
          ) : null}
          <div className={styles.matchWith}>
            <span>Selective stok no ile eşleştir. </span>
            <Input
              placeholder={"Selective stkno"}
              onChange={(e) => setStkno(e.target.value)}
            />
            <Button onClick={() => matchStkno()}>Eşleştir</Button>
          </div>
        </div>
      </Modal>
    );
  else setIsOpen(false);
}

export default ProductModal;
