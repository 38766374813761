module.exports = {
  "İHRAÇ KAYITLI": [
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "TÜRKİYE İŞ BANKASI A.Ş.",
      swiftCode: "ISBKTRISXXX",
      bankBranchCode: "2248/OSB-İNEGÖL",
      bankAddress: "SİNANBEY MH.ALANYURT CD. NO:9A İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL    TR74 0006 4000 0012 2480 0026 72",
        "USD   TR03 0006 4000 0022 2480 0011 32",
        "EURO  TR41 0006 4000 0022 2480 0011 27",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "GARANTİ BANKASI A.Ş.",
      swiftCode: "TGBATRISXXX",
      bankBranchCode: "1667/ULUDAĞ TİCARİ",
      bankAddress: "KIRCAALİ,FEVZİ ÇAKMAK CD.NO:60 OSMANGAZİ/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR73 0006 2001 6670 0006 2990 79",
        "USD  TR28 0006 2001 6670 0009 0967 87",
        "EURO  TR51 0006 2001 6670 0009 0988 95",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "YAPI VE KREDİ BANKASI A.Ş.",
      swiftCode: "YAPITRISXXX",
      bankBranchCode: "021/İNEGÖL",
      bankAddress: "CUMA MH.TABAKHANE SK.NO:6 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR90 0006 7010 0000 0047 5537 95",
        "USD  TR64 0006 7010 0000 0051 4276 67",
        "EURO  TR30 0006 7010 0000 0055 9939 72",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "T.C. ZİRAAT BANKASI A.Ş.",
      swiftCode: "TCZBTR2AXXX",
      bankBranchCode: "067/İNEGÖL",
      bankAddress: "SİNANBEY MH.ZAFER CAD.NO:1 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR36 0001 0000 6757 2432 9950 11",
        "USD  TR09 0001 0000 6757 2432 9950 12",
        "EURO  TR79 0001 0000 6757 2432 9950 13",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "QNB FİNANSBANK A.Ş.",
      swiftCode: "FNNBTRIS",
      bankBranchCode: "165/İNEGÖL",
      bankAddress: "SİNANBEY MH.YENİŞEHİR CD.NO:7 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR94 0011 1000 0000 0018 4441 42",
        "USD  TR22 0011 1000 0000 0087 6005 21",
        "EURO  TR42 0011 1000 0000 0087 6005 49",
      ],
    },
  ],
  İHRACATÖZCANLAR: [
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "TÜRKİYE İŞ BANKASI A.Ş.",
      swiftCode: "ISBKTRISXXX",
      bankBranchCode: "2248/OSB-İNEGÖL",
      bankAddress: "SİNANBEY MH.ALANYURT CD. NO:9A İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL    TR74 0006 4000 0012 2480 0026 72",
        "USD   TR03 0006 4000 0022 2480 0011 32",
        "EURO  TR41 0006 4000 0022 2480 0011 27",
        "GBP TR47 0006 4000 0022 2480 0230 38",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "GARANTİ BANKASI A.Ş.",
      swiftCode: "TGBATRISXXX",
      bankBranchCode: "1667/ULUDAĞ TİCARİ",
      bankAddress: "KIRCAALİ,FEVZİ ÇAKMAK CD.NO:60 OSMANGAZİ/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR73 0006 2001 6670 0006 2990 79",
        "USD  TR28 0006 2001 6670 0009 0967 87",
        "EURO  TR51 0006 2001 6670 0009 0988 95",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "T.C. ZİRAAT BANKASI A.Ş.",
      swiftCode: "TCZBTR2AXXX",
      bankBranchCode: "067/İNEGÖL",
      bankAddress: "SİNANBEY MH.ZAFER CAD.NO:1 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR36 0001 0000 6757 2432 9950 11",
        "USD  TR09 0001 0000 6757 2432 9950 12",
        "EURO  TR79 0001 0000 6757 2432 9950 13",
        "GBP TR95 0001 0000 6757 2432 9950 16",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "QNB FİNANSBANK A.Ş.",
      swiftCode: "FNNBTRIS",
      bankBranchCode: "165/İNEGÖL",
      bankAddress: "SİNANBEY MH.YENİŞEHİR CD.NO:7 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL  TR94 0011 1000 0000 0018 4441 42",
        "USD  TR22 0011 1000 0000 0087 6005 21",
        "EURO  TR42 0011 1000 0000 0087 6005 49",
        "GBP TR71 0011 1000 0000 0092 1775 54",
      ],
    },
  ],
  YURTİÇİ: [
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "TÜRKİYE İŞ BANKASI A.Ş.",
      swiftCode: "ISBKTRISXXX",
      bankBranchCode: "2248/OSB-İNEGÖL",
      bankAddress: "SİNANBEY MH.ALANYURT CD. NO:9A İNEGÖL/BURSA/TÜRKİYE",
      IBANS: ["TL    TR74 0006 4000 0012 2480 0026 72"],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "GARANTİ BANKASI A.Ş.",
      swiftCode: "TGBATRISXXX",
      bankBranchCode: "1667/ULUDAĞ TİCARİ",
      bankAddress: "KIRCAALİ,FEVZİ ÇAKMAK CD.NO:60 OSMANGAZİ/BURSA/TÜRKİYE",
      IBANS: ["TL  TR73 0006 2001 6670 0006 2990 79"],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "YAPI VE KREDİ BANKASI A.Ş.",
      swiftCode: "YAPITRISXXX",
      bankBranchCode: "021/İNEGÖL",
      bankAddress: "CUMA MH.TABAKHANE SK.NO:6 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: ["TL  TR90 0006 7010 0000 0047 5537 95"],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "T.C. ZİRAAT BANKASI A.Ş.",
      swiftCode: "TCZBTR2AXXX",
      bankBranchCode: "067/İNEGÖL",
      bankAddress: "SİNANBEY MH.ZAFER CAD.NO:1 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: ["TL  TR36 0001 0000 6757 2432 9950 11"],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "QNB FİNANSBANK A.Ş.",
      swiftCode: "FNNBTRIS",
      bankBranchCode: "165/İNEGÖL",
      bankAddress: "SİNANBEY MH.YENİŞEHİR CD.NO:7 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: ["TL  TR94 0011 1000 0000 0018 4441 42"],
    },
  ],
  "İHRACATSEÇİM YOK": [
    {
      accName: "LETA SANDALYE PROJE VE TASARIM TİC LTD ŞTİ",
      bankName: "TÜRKİYE İŞ BANKASI A.Ş.",
      swiftCode: "ISBKTRISXXX",
      bankBranchCode: "2248/OSB-İNEGÖL",
      bankAddress: "SİNANBEY MH.ALANYURT CD. NO:9A İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR04 0006 4000 0012 2480 0454 48",
        "USD TR78 0006 4000 0022 2480 0077 36",
        "EURO TR67 0006 4000 0022 2480 0077 40",
        "GBP TR92 0006 4000 0022 2480 0222 28",
      ],
    },

    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "T.C. ZİRAAT BANKASI A.Ş.",
      swiftCode: "TCZBTR2AXXX",
      bankBranchCode: "067/İNEGÖL",
      bankAddress: "SİNANBEY MH.ZAFER CAD.NO:1 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR06 0001 0000 6776 7986 1350 10",
        "USD TR76 0001 0000 6776  7986 1350 11",
        "EURO TR49 0001 0000 6776 7986 1350 12",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "QNB FİNANSBANK A.Ş.",
      swiftCode: "FNNBTRIS",
      bankBranchCode: "165/İNEGÖL",
      bankAddress: "SİNANBEY MH.YENİŞEHİR CD.NO:7 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR24 0011 1000 0000 0088 1642 49",
        "USD TR15 0011 1000 0000 0088 1643 14",
        "EURO TR42 0011 1000 0000 0088 1645 07",
      ],
    },
  ],
  "İHRACATLETA PROJE": [
    {
      accName: "LETA SANDALYE PROJE VE TASARIM TİC LTD ŞTİ",
      bankName: "TÜRKİYE İŞ BANKASI A.Ş.",
      swiftCode: "ISBKTRISXXX",
      bankBranchCode: "2248/OSB-İNEGÖL",
      bankAddress: "SİNANBEY MH.ALANYURT CD. NO:9A İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR04 0006 4000 0012 2480 0454 48",
        "USD TR78 0006 4000 0022 2480 0077 36",
        "EURO TR67 0006 4000 0022 2480 0077 40",
        "GBP TR92 0006 4000 0022 2480 0222 28",
      ],
    },

    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "T.C. ZİRAAT BANKASI A.Ş.",
      swiftCode: "TCZBTR2AXXX",
      bankBranchCode: "067/İNEGÖL",
      bankAddress: "SİNANBEY MH.ZAFER CAD.NO:1 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR06 0001 0000 6776 7986 1350 10",
        "USD TR76 0001 0000 6776  7986 1350 11",
        "EURO TR49 0001 0000 6776 7986 1350 12",
      ],
    },
    {
      accName: "ÖZCANLAR SANDALYE SAN TİC LTD ŞTİ",
      bankName: "QNB FİNANSBANK A.Ş.",
      swiftCode: "FNNBTRIS",
      bankBranchCode: "165/İNEGÖL",
      bankAddress: "SİNANBEY MH.YENİŞEHİR CD.NO:7 İNEGÖL/BURSA/TÜRKİYE",
      IBANS: [
        "TL TR24 0011 1000 0000 0088 1642 49",
        "USD TR15 0011 1000 0000 0088 1643 14",
        "EURO TR42 0011 1000 0000 0088 1645 07",
      ],
    },
  ],
};
