import React from "react";
import styles from "./login.module.css";
import { Form, Input, Button, message } from "antd";
import "antd/dist/antd.css";
import Logo from "../../images/letalogo.png";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import { inject, observer } from "mobx-react";
import APIKit, { setClientToken } from "../../shared/APIKit";
import jwt_decode from "jwt-decode";

const Login = inject("MainStore")(
  observer((props) => {
    const [loading, setLoading] = React.useState(false);

    const onFinish = (values) => {
      setLoading(true);
      APIKit.post("/api/user/login", values)
        .then((res) => {
          const { token } = res.data;
          localStorage.setItem("jwtToken", token);

          setClientToken(token);
          const decoded = jwt_decode(token);
          props.MainStore.setAuth(decoded);
          setLoading(false);

          if (props.MainStore.auth.isAuthenticated)
            props.history.push("/home/products");
        })
        .catch((err) => {
          message.error("Kullanıcı adı veya şifre yanlış!");
          setLoading(false);
        });
    };

    React.useEffect(() => {
      setTimeout(() => {
        if (props.MainStore.auth.isAuthenticated === true) {
          props.history.push("/home/products");
        }
      }, 30);
    }, [props.history]);

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <LoadingOverlay active={loading} spinner text={"Yükleniyor..."}>
        <div className={styles.mainContainer}>
          <div className={styles.left}>
            <img src={Logo} />
          </div>
          <div className={styles.right}>
            <h1>Giriş Yap</h1>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Kullanıcı Adınızı Giriniz!",
                  },
                ]}
              >
                <Input
                  placeholder={"Kullanıcı Adı"}
                  size="large"
                  style={{ width: 300 }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Lütfen Şifrenizi giriniz" },
                ]}
              >
                <Input.Password
                  placeholder={"Şifre"}
                  size="large"
                  style={{ width: 300 }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{
                    width: 300,
                    backgroundColor: "var(--secondary-color)",
                    border: 0,
                    borderRadius: 40,
                  }}
                >
                  Onayla
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </LoadingOverlay>
    );
  })
);

export default Login;
