import React from "react";
import { Modal, Input } from "antd";
import styles from "./currentmodal.module.css";

function CurrentModal({ isOpen, setIsOpen, current }) {
  const { TextArea } = Input;
  return (
    <Modal
      title="Cari Detayları"
      visible={isOpen}
      width={800}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Hesap Kodu : " + current.hesap}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Müşteri Ünvanı : " + current.unvan}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Telefon : " + current.telefon}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Tc : " + current.tcno}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Para Birimi : " + current.parabirimi}
          />

          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Posta Kodu : " + current.postakod}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Posta Kodu : " + current.vergino}
          />
          <TextArea
            disabled={true}
            className={styles.input}
            placeholder={"Adres : " + current.unvan}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Grup : " + current.grup}
          />
        </div>
        <div className={styles.right}>
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Plasiyer : " + current.plasiyer}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Alıcı : " + current.alici}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Mail : " + current.mail}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Yetkili : " + current.yetkili}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"İl : " + current.il}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"İlçe : " + current.ilce}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Ülke : " + current.ulke}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Vergi Dairesi : " + current.vergida}
          />
        </div>
        <div className={styles.border} />

        <div className={styles.fixRight}>
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Sevk İl : " + current.sevkil}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Sevk İlçe : " + current.sevkilce}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Sevk Ülke : " + current.sevkulke}
          />

          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Sevk Kodu : " + current.sevkpkod}
          />
          <Input
            disabled={true}
            className={styles.input}
            placeholder={"Sevk Tel : " + current.sevktel}
          />
          <TextArea
            disabled={true}
            className={styles.input}
            placeholder={"Sevk Adres : " + current.sevkadr}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CurrentModal;
