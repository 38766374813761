// EN
import BasinEN from "./EN/basin.jpg";
import IhracatEN from "./EN/ihracat.jpg";
import InegolEN from "./EN/inegol.jpg";
import KozyatagiEN from "./EN/kozyatagi.jpg";
import LeventEN from "./EN/levent.jpg";
import NiluferEN from "./EN/nilufer.jpg";

// TR
import BasinTR from "./TR/basin.jpg";
import IhracatTR from "./TR/ihracat.jpg";
import InegolTR from "./TR/inegol.jpg";
import KozyatagiTR from "./TR/kozyatagi.jpg";
import LeventTR from "./TR/levent.jpg";
import NiluferTR from "./TR/nilufer.jpg";

const returnLanguage = (lang) => {
  switch (lang) {
    case "tr":
      return [BasinTR, IhracatTR, InegolTR, KozyatagiTR, LeventTR, NiluferTR];
    case "en":
      return [BasinEN, IhracatEN, InegolEN, KozyatagiEN, LeventEN, NiluferEN];
  }
};

const returnTopBanner = (language, magaza) => {
  const banners = returnLanguage(language);

  switch (magaza) {
    case "Basınekspres":
      return banners[0];
    case "Leta İhracat":
      return banners[1];
    case "Leta Inegol":
      return banners[2];
    case "Kozyatağı":
      return banners[3];
    case "4.Levent":
      return banners[4];
    case "Nilüfer":
      return banners[5];
    default:
      return "";
  }
};

export { returnTopBanner };
