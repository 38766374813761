import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import Loading from "react-loading";
import APIKit from "../../../shared/APIKit";
import s3Config from "../../../config/s3Config";
import S3 from "react-aws-s3";

const Image = React.memo(function Image({ src, ...rest }) {
  return <img src={src} {...rest} />;
});

const ImageChange = ({ isOpen, setIsOpen, selectedUser, setSelectedUser }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [image, setImage] = useState(
    selectedUser?._id + ".jpg?" + new Date().getTime()
  );
  const imgRef = useRef();

  useEffect(() => {
    setLoading(false);
    setError(false);
    setImage(selectedUser?._id + ".jpg?" + new Date().getTime());
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (!selectedUser) setIsOpen(false);
  }, [selectedUser]);

  const handleUpload = (e) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", e.target.files[0]);

    const config = {
      bucketName: s3Config.bucketName,
      dirName: "leta/imzalar",
      region: "eu-central-1",
      accessKeyId: s3Config.accessKeyId,
      secretAccessKey: s3Config.secretAccessKey,
    };

    const ReactS3Client = new S3(config);
    const fileName = selectedUser?._id + ".jpg";

    ReactS3Client.uploadFile(e.target.files[0], fileName).then((data) => {
      setImage(selectedUser?._id + ".jpg?" + new Date().getTime());
      setLoading(false);
    });
  };

  return (
    <Modal
      title="Imza Ekle"
      visible={isOpen}
      width={400}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {!loading && !error ? (
          <Image
            key={new Date()}
            onError={() => setError(true)}
            onLoadStart={() => setLoading(true)}
            ref={imgRef}
            src={`https://cloudselective.s3.eu-central-1.amazonaws.com/leta/imzalar/${image}`}
            style={{
              maxWidth: "100%",
            }}
          />
        ) : (
          loading && (
            <Loading
              type={"spin"}
              color={"var(--primary-color)"}
              height={100}
              width={100}
            />
          )
        )}

        <input
          onChange={handleUpload}
          type="file"
          id="file-input"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
        />

        <label
          htmlFor="file-input"
          style={{
            marginTop: 20,
            backgroundColor: "var(--primary-color)",
            width: "100%",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
          }}
        >
          {error ? "Imza Ekle" : "Imzayi degistir"}
        </label>
      </div>
    </Modal>
  );
};

export default ImageChange;
