import React from "react";

const BankItems = ({ banks, lang }) => {
  console.log("BANSK");
  console.log(banks);

  const styles = {
    rowStyle: {
      padding: 0.5,
      borderBottom: "1px solid black",
    },
  };
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
      }}
    >
      <div
        style={{
          width: 20,
          marginTop: 100,
          transform: "rotate(-90deg)",
          transformOrigin: "0 0",
        }}
      >
        {lang === "tr" ? "BANKA" : "BANK"}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {banks &&
          banks?.length > 0 &&
          banks?.map((bank, i) => {
            return (
              <div
                style={{
                  fontSize: 7,
                  width: "50%",
                  borderRight: i % 2 === 0 ? "1px solid black" : "none",
                  display: "grid",
                  gridTemplateColumns: "52% 43%",
                }}
              >
                <span
                  style={{
                    gridColumn: "1 / span 2",
                    textAlign: "center",
                    fontSize: 13,
                    borderBottom: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {bank.bankName}
                </span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr" ? "HESAP İSMİ" : "BENEFICIARY ACCOUNT NAME"}
                </span>
                <span style={styles.rowStyle}>{bank.accName}</span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr" ? "BANKA ADI" : "BENEFICIARY BANK NAME"}
                </span>
                <span style={styles.rowStyle}>{bank.bankName}</span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr" ? "SWIFT KODU" : "BENEFICIARY BANK SWIFT CODE"}
                </span>
                <span style={styles.rowStyle}>{bank.swiftCode}</span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr"
                    ? "BANKA ŞUBE KODU"
                    : "BENEFICIARY BANK BRANCH CODE/NAME"}
                </span>
                <span style={styles.rowStyle}>{bank.bankBranchCode}</span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr" ? "BANKA ADRESİ" : "BENEFICIARY BANK ADDRESS"}
                </span>
                <span style={styles.rowStyle}>{bank.bankAddress}</span>
                <span
                  style={{
                    ...styles.rowStyle,
                    borderRight: "1px solid black",
                    borderLeft: i % 2 === 0 ? "1px solid black" : "none",
                  }}
                >
                  {lang === "tr"
                    ? "IBAN ADRESLERİ"
                    : "BENEFICIARY ACCOUNT IBAN NUMBER"}
                </span>
                <span
                  style={{
                    ...styles.rowStyle,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {bank["IBANS"].map((iban) => (
                    <span>{iban}</span>
                  ))}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BankItems;
