import React, { useState, useCallback, useEffect } from "react";
import ReactLoading from "react-loading";
import APIKit from "../../shared/APIKit";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import styles from "./oldoffers.module.css";
import { numberToMoney } from "../../utils/util";
import { useContextMenu, Menu, Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { message, Button, Checkbox, Select, Modal } from "antd";
import { FaFirstdraft } from "react-icons/fa";

import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
//Modals
const MENU_ID = "CONTEXT";

const Drafts = inject("MainStore")(
  observer(({ MainStore, location }) => {
    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState();
    const history = useHistory();
    const tableitems = [
      {
        label: "Taslak Adı",
        value: "offerName",
        width: 250,
      },
      {
        label: "Cari",
        value: "current.unvan",
        width: 360,
      },
      {
        label: "Mağaza",
        value: "magaza",
        width: 250,
      },
      {
        label: "Para Birimi",
        value: "currencyUnit",
        width: 40,
      },
      {
        label: "KDV Dahil Fiyat",
        value: "totalPriceWithKDV",
        width: 230,
      },
    ];

    const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      setIsGridReady(true);

      params.api.onFilterChanged();
    };

    const onSelectionChanged = (params) => {
      if (isGridReady && gridColumnApi) {
        let selectedNodes = gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
        setSelectedOffer(selectedData[0]);
      }
    };

    useEffect(() => {
      MainStore.getDrafts();
    }, []);

    const useDraft = (data) => {
      MainStore.useDraft(data);
    };

    const gridOptions = {
      pagination: false,
    };

    const OnRowDoubleClicked = (data) => {
      //useDraft(data.data);
    };

    var contextMenu = [
      {
        name: "Taslağı Kullan",
        dispatch: "use",
      },
      {
        name: "Taslağı Sil",
        dispatch: "delete",
      },
    ];

    const ContextMenu = ({ contextMenu }) => {
      const { show } = useContextMenu({
        id: MENU_ID,
      });

      function displayMenu(e) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e);
      }

      const Use = (draft) => {
        useDraft(draft._id);
      };

      const Delete = (draft) => {
        Modal.confirm({
          title: "Taslağı silmek istediğinize emin misiniz?",
          okText: "Evet",
          cancelText: "Hayır",
          onOk() {
            MainStore.deleteDraft(draft._id);
            message.success("Taslağınız silindi.");
            gridApi.refreshCells();
          },
        });
      };

      const UseOrDelete = (useOrDelete, draft) => {
        if (useOrDelete) {
          Use(draft);
        } else {
          Delete(draft);
        }
      };

      return (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <Menu id={MENU_ID}>
            {contextMenu.map((item, index) => {
              if (selectedOffer) {
                return (
                  <Item
                    key={index}
                    onClick={() => {
                      if (selectedOffer) {
                        if (item.dispatch === "use") {
                          UseOrDelete(true, selectedOffer);
                          history.push("/home/offer");
                          message.success("Taslak kullanımda!");
                        } else if (item.dispatch === "delete") {
                          UseOrDelete(false, selectedOffer);
                        }
                      } else message.error("Bir taslak seçmelisiniz!");
                    }}
                  >
                    {item.name}
                  </Item>
                );
              }
            })}
          </Menu>
        </div>
      );
    };

    const { show } = useContextMenu({
      id: "CONTEXT",
    });

    if (MainStore.loadingDrafts)
      return (
        <div className={styles.centeredDiv}>
          <ReactLoading
            type={"balls"}
            color={"var(--primary-color)"}
            height={300}
            width={150}
          />
        </div>
      );
    else if (MainStore?.drafts?.length <= 0)
      return (
        <div className={styles.notFound}>
          <FaFirstdraft className={styles.icon} />
          <span>Hiç taslak yok!</span>
        </div>
      );
    else
      return (
        <div className={styles.container}>
          <div
            id="myGrid"
            className="ag-theme-alpine"
            onContextMenu={show}
            style={{ width: "100%", height: "100%" }}
          >
            <ContextMenu contextMenu={contextMenu} />

            <AgGridReact
              modules={[AllCommunityModules]}
              onGridReady={onGridReady}
              rowData={MainStore.drafts}
              rowSelection="single"
              gridOptions={gridOptions}
              onRowDoubleClicked={OnRowDoubleClicked}
              onSelectionChanged={onSelectionChanged}
              immutableData={false}
              getRowNodeId={(data) => data._id}
              defaultColDef={{
                width: 150,
                cellStyle: {
                  textAlign: "left",
                  paddingLeft: 5,
                },
                sortable: true,
                filter: true,
                floatingFilter: true,
                editable: false,
                resizable: true,
                type: "leftAligned",
              }}
            >
              {tableitems.map((item, idx) => {
                return (
                  <AgGridColumn
                    key={idx}
                    headerName={item.label}
                    field={item.value}
                    minWidth={item?.width}
                  />
                );
              })}
            </AgGridReact>
          </div>
        </div>
      );
  })
);

export default Drafts;
