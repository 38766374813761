import React, { useState, useCallback } from "react";
import ReactLoading from "react-loading";
import APIKit from "../../shared/APIKit";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import styles from "./oldoffers.module.css";
import { numberToMoney } from "../../utils/util";
import { useContextMenu, Menu, Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { message, Button, Checkbox, Select, Modal } from "antd";
import { FaFirstdraft } from "react-icons/fa";
import PrintPDF from "../../components/printOfferCart/PrintPDF";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { inject, observer } from "mobx-react";
//Modals
import OldRevizes from "../../components/oldRevizes/OldRevizes";
import OfferDetails from "../../components/offerDetails/OfferDetails";

import lang from "../../components/printOfferCart/lang";
const MENU_ID = "CONTEXT";

const OldOffers = inject("MainStore")(
  observer(({ MainStore, location }) => {
    const [loading, setLoading] = useState(true);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [activeOffers, setActiveOffers] = useState([]);
    const [notActiveOffers, setNotActiveOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState();
    const [project, setProject] = useState(false);
    const [plasiyer, setPlasiyer] = useState(null);
    const [magaza, setMagaza] = useState(null);
    const [beforePrint, setBeforePrint] = React.useState(false);
    const [isActiveOffers, setIsActiveOffers] = useState(true);
    const [status, setStatus] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState({
      detaylar: false,
      revizeEt: false,
      eskiRevizeler: false,
    });
    const [printInfo, setPrintInfo] = React.useState({
      lang: "tr",
      hide: false,
      type: "DİĞER",
      type2: "Seçim Yok",
    });
    var printRef = React.useRef();

    var ageType = "everyone";

    const floatParser = (data) => {
      if (parseFloat(data)) return parseFloat(data);
      else return 0;
    };

    React.useEffect(() => {
      const params = window.location.search;
      const param = new URLSearchParams(params);
      const status = param.get("status");
      const plasiyer = param.get("plasiyer");
      const magaza = param.get("magaza");
      setMagaza(magaza);
      setPlasiyer(plasiyer);
      setStatus(status);
    }, []);

    const isExternalFilterPresent = () => {
      return plasiyer !== "" && status !== "";
    };

    const externalFilterChanged = (plasiyer, status) => {
      setStatus(status);
      setPlasiyer(plasiyer);
      gridApi.onFilterChanged();
    };

    const loadData = useCallback(() => {
      APIKit.get("/api/offer").then(({ data }) => {
        var actives = [];
        var notActives = [];

        if (data) {
          for (var i = 0; i < Object.keys(data).length; i++) {
            data[i]["withDiscountPrice"] = 0;
            data[i]["withoutDiscountPrice"] = 0;
            data[i]["withoutKdvPrice"] = 0;
            data[i]["withDiscountPriceParsed"] = 0;
            data[i]["discount"] = 0;
            data[i]["revizeCount"] = Object.keys(data[i].offers).length - 1;

            for (
              var ic = 0;
              ic <
              Object.keys(
                data[i].offers[Object.keys(data[i].offers).length - 1]
              ).length;
              ic++
            ) {
              data[i].withoutKdvPrice +=
                ((100 -
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .discount3) /
                  100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount2) /
                    100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount1) /
                    100) *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .price *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .count -
                data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                  .totalDiscount +
                floatParser(
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .kataForez
                ) +
                floatParser(
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .kumasFarki
                );

              data[i].withoutDiscountPrice +=
                data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                  .count *
                data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                  .price;

              data[i].withDiscountPrice +=
                (((100 -
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .discount3) /
                  100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount2) /
                    100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount1) /
                    100) *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .price *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .count -
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .totalDiscount +
                  floatParser(
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .kataForez
                  ) *
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .count +
                  floatParser(
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .kumasFarki
                  ) *
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .count) *
                  (floatParser(
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .kdv
                  ) /
                    100) +
                (((100 -
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .discount3) /
                  100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount2) /
                    100) *
                  ((100 -
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .discount1) /
                    100) *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .price *
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .count -
                  data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                    .totalDiscount +
                  floatParser(
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .kataForez
                  ) *
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .count +
                  floatParser(
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .kumasFarki
                  ) *
                    data[i].offers[Object.keys(data[i].offers).length - 1][ic]
                      .count);
            }

            data[i].discount =
              data[i].withoutDiscountPrice - data[i].withDiscountPrice;

            data[i].discount = numberToMoney(
              data[i].discount < 0 ? 0 : data[i].discount,
              data[i].currenyUnit
            );

            data[i].withDiscountPriceParsed = numberToMoney(
              data[i].withDiscountPrice,
              data[i].currenyUnit
            );

            data[i].withoutDiscountPrice = numberToMoney(
              data[i].withoutDiscountPrice,
              data[i].currenyUnit
            );
            if (data[i].isOpen) actives.push(data[i]);
            else notActives.push(data[i]);
          }
          setActiveOffers(actives);
          setNotActiveOffers(notActives);
          setLoading(false);
        }
      });
    }, []);

    const printIt = useReactToPrint({
      content: () => printRef,
    });

    React.useEffect(() => {
      loadData();
      /*
      const params = window.location.search;
      const para = new URLSearchParams(params);
      const plasiyer = para.get("plasiyer");
      const status = para.get("status");
      */
    }, [loadData]);

    const tableitems = [
      {
        label: "Teklif Adı",
        value: "offerName",
        width: 250,
      },
      {
        label: "Cari",
        value: "current.unvan",
        width: 360,
      },
      {
        label: "Mağaza",
        value: "magaza",
        width: 250,
      },
      {
        label: "Revize Miktarı",
        value: "revizeCount",
        width: 40,
      },
      {
        label: "Açıklama",
        value: "explanation",
        width: 230,
      },
      {
        label: "Durumu",
        value: "status",
        width: 230,
      },
      {
        label: "Genel Fiyat",
        value: "withDiscountPriceParsed",
        width: 230,
      },
      {
        label: "Plasiyer",
        value: "plasiyer",
      },
      {
        label: "Oluşturulma Tarihi",
        value: "createDate",
        width: 200,
      },
    ];

    const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      setIsGridReady(true);

      params.api.onFilterChanged();
    };

    const onSelectionChanged = (params) => {
      if (isGridReady && gridColumnApi) {
        let selectedNodes = gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
        setSelectedOffer(selectedData[0]);
      }
    };

    const gridOptions = {
      pagination: false,
    };

    const onRowDoubleClicked = () => {
      setIsOpen(true);
    };

    var contextMenu = [
      {
        name: "Siparişe Dönüştür",
        dispatch: "sipariseDonustur",
      },
      {
        name: "Revize Et",
        dispatch: "revizeEt",
      },
      {
        name: "Eski Revizeleri Gör",
        dispatch: "eskiRevizeler",
      },
      {
        name: "Teklifi Kapat",
        dispatch: "teklifikapat",
      },
      {
        name: "Detaylar",
        dispatch: "detaylar",
      },
    ];

    const ContextMenu = ({ contextMenu }) => {
      const { show } = useContextMenu({
        id: MENU_ID,
      });

      function displayMenu(e) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show(e);
      }

      return (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <Menu id={MENU_ID}>
            {contextMenu.map((item, index) => {
              if (selectedOffer) {
                if (item.name === "Teklifi Kapat") {
                  return (
                    <Item
                      key={index}
                      onClick={() => {
                        APIKit.post("/api/offer/isopen", {
                          offerId: selectedOffer._id,
                          isOpen: !selectedOffer.isOpen,
                        })
                          .then((res) => {
                            if (selectedOffer.isOpen) {
                              message.success("Teklif kapatıldı.");
                            } else {
                              message.success("Teklif açıldı.");
                            }

                            loadData();
                          })
                          .catch((err) => {
                            message.error("Teklif kapatılamadı!");
                          });
                      }}
                    >
                      {selectedOffer.isOpen ? "Teklifi Kapat" : "Teklifi Aç"}
                    </Item>
                  );
                } else if (
                  item.name === "Siparişe Dönüştür" &&
                  selectedOffer.status !== "Onaylandı" &&
                  selectedOffer.status !== "Siparişe Dönüştü" &&
                  selectedOffer.status !== "Reddedildi" &&
                  selectedOffer.status !== "Onayda Bekleyen" &&
                  selectedOffer.isOpen
                ) {
                  return (
                    <Item
                      key={index}
                      onClick={() => {
                        if (selectedOffer) {
                          setIsModalOpen({
                            ...isModalOpen,
                            detaylar: true,
                          });
                          setProject(true);
                        } else message.error("Bir teklif seçmelisiniz!");
                      }}
                    >
                      {item.name}
                    </Item>
                  );
                } else if (
                  item.name === "Revize Et" &&
                  selectedOffer.status !== "Onaylandı" &&
                  selectedOffer.status !== "Reddedildi" &&
                  selectedOffer.status !== "Onaylandı (Projesiz)" &&
                  selectedOffer.status !== "Siparişe Dönüştü" &&
                  selectedOffer.status !== "Onaylandı (Projeli)" &&
                  item.name !== "Siparişe Dönüştür" &&
                  selectedOffer.isOpen
                )
                  return (
                    <Item
                      key={index}
                      onClick={() => {
                        if (selectedOffer) {
                          MainStore.setRevize(true);
                          MainStore.setCurrent(selectedOffer.current);
                          MainStore.setRevizeOffer(selectedOffer);
                          MainStore.setOfferCart(
                            selectedOffer.offers[
                              Object.keys(selectedOffer.offers).length - 1
                            ]
                          );

                          setIsOpen(false);
                          message.info(
                            "Teklif ekranından revize işlemine devam edebilirsiniz."
                          );
                        } else message.error("Bir teklif seçmelisiniz!");
                      }}
                    >
                      {item.name}
                    </Item>
                  );
                else if (
                  Object.keys(selectedOffer.offers).length > 1 &&
                  item.name === "Eski Revizeleri Gör" &&
                  item.name !== "Revize Et" &&
                  item.name !== "Siparişe Dönüştür" &&
                  selectedOffer.isOpen
                ) {
                  if (selectedOffer)
                    return (
                      <Item
                        key={index}
                        onClick={() => {
                          if (selectedOffer)
                            setIsModalOpen({
                              ...isOpen,
                              [item.dispatch]: true,
                            });
                          else message.error("Bir teklif seçmelisiniz!");
                        }}
                      >
                        {item.name}
                      </Item>
                    );
                  else return null;
                } else if (
                  item.name !== "Eski Revizeleri Gör" &&
                  item.name !== "Revize Et" &&
                  item.name !== "Siparişe Dönüştür" &&
                  selectedOffer.isOpen
                )
                  return (
                    <Item
                      key={index}
                      onClick={() => {
                        if (selectedOffer)
                          setIsModalOpen({
                            ...isOpen,
                            [item.dispatch]: true,
                          });
                        else message.error("Bir teklif seçmelisiniz!");
                      }}
                    >
                      {item.name}
                    </Item>
                  );
                else return null;
              }
            })}
          </Menu>
        </div>
      );
    };

    const doesExternalFilterPass = (node) => {
      if (node.data.status.includes(status) && node.data.magaza === magaza) {
        return true;
      } else if (status && magaza) {
        return false;
      } else if (magaza) {
        return node.data.magaza.includes(magaza);
      } else if (status) {
        return node.data.status.includes(status);
      } else if (plasiyer) {
        switch (plasiyer) {
          case plasiyer:
            return node.data.plasiyer === plasiyer;
          default:
            return false;
        }
      } else return true;
    };

    const { show } = useContextMenu({
      id: "CONTEXT",
    });

    if (loading)
      return (
        <div className={styles.centeredDiv}>
          <ReactLoading
            type={"balls"}
            color={"var(--primary-color)"}
            height={300}
            width={150}
          />
        </div>
      );
    else if (!activeOffers[0] && !notActiveOffers[0])
      return (
        <div className={styles.notFound}>
          <div className={styles.rightIn}>
            <button
              style={{
                border: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                color: "white",
                cursor: "pointer",
                opacity: isActiveOffers ? 1 : 0.3,
                backgroundColor: isActiveOffers
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
              }}
              onClick={() => setIsActiveOffers(true)}
            >
              Açık teklifler
            </button>
            <button
              onClick={() => setIsActiveOffers(false)}
              style={{
                marginRight: "auto",
                border: 0,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                color: "white",
                cursor: "pointer",
                opacity: !isActiveOffers ? 1 : 0.3,
                backgroundColor: !isActiveOffers
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
              }}
            >
              Kapalı teklifler
            </button>
          </div>

          <FaFirstdraft className={styles.icon} />
          <span>Hiç teklif yok!</span>
        </div>
      );
    else
      return (
        <div className={styles.container}>
          <div className={styles.rightIn}>
            <button
              style={{
                border: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                color: "white",
                cursor: "pointer",
                opacity: isActiveOffers ? 1 : 0.3,
                backgroundColor: isActiveOffers
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
              }}
              onClick={() => setIsActiveOffers(true)}
            >
              Açık teklifler
            </button>
            <button
              onClick={() => setIsActiveOffers(false)}
              style={{
                marginRight: "auto",
                border: 0,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                color: "white",
                cursor: "pointer",
                opacity: !isActiveOffers ? 1 : 0.3,
                backgroundColor: !isActiveOffers
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
              }}
            >
              Kapalı teklifler
            </button>
            <div
              style={{
                opacity: 0,
              }}
            >
              <ReactToPrint
                ref={printRef}
                onAfterPrint={() => {}}
                trigger={() => (
                  <Button
                    disabled={selectedOffer ? false : true}
                    onClick={() => {}}
                    className={styles.buttonClassic}
                  >
                    Çıktı Al
                  </Button>
                )}
                content={() => printRef}
              />
            </div>
            <Button
              disabled={selectedOffer ? false : true}
              onClick={() => setBeforePrint(true)}
              className={styles.buttonClassic}
            >
              Çıktı Al
            </Button>
          </div>

          <div
            id="myGrid"
            className="ag-theme-alpine"
            onContextMenu={show}
            style={{ width: "100%", height: "100%" }}
          >
            <ContextMenu contextMenu={contextMenu} />

            <AgGridReact
              modules={[AllCommunityModules]}
              onGridReady={onGridReady}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              rowData={isActiveOffers ? activeOffers : notActiveOffers}
              rowSelection="single"
              gridOptions={gridOptions}
              onRowDoubleClicked={onRowDoubleClicked}
              onSelectionChanged={onSelectionChanged}
              immutableData={false}
              getRowNodeId={(data) => data._id}
              defaultColDef={{
                width: 150,
                cellStyle: {
                  textAlign: "left",
                  paddingLeft: 5,
                },
                sortable: true,
                filter: true,
                floatingFilter: true,
                editable: false,
                resizable: true,
                type: "leftAligned",
                isExternalFilterPresent: isExternalFilterPresent,
              }}
            >
              {tableitems.map((item, idx) => {
                return (
                  <AgGridColumn
                    key={idx}
                    headerName={item.label}
                    field={item.value}
                    minWidth={item?.width}
                  />
                );
              })}
            </AgGridReact>
          </div>

          {selectedOffer ? (
            <>
              <OfferDetails
                printIt={() => setBeforePrint(true)}
                setProject={setProject}
                project={project}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                selectedOffer={selectedOffer}
                currenyUnit={selectedOffer.currenyUnit}
                withDiscountPrice={selectedOffer.withDiscountPriceParsed}
                withoutDiscountPrice={selectedOffer.withoutDiscountPrice}
                discount={selectedOffer.discount}
                offerId={selectedOffer._id}
                loadData={loadData}
                setLoading={setLoading}
                status={selectedOffer.status}
              />
              <OldRevizes
                isOpen={isModalOpen.eskiRevizeler}
                floatParser={floatParser}
                setIsOpen={(val) =>
                  setIsModalOpen({ ...isOpen, eskiRevizeler: val })
                }
                selectedOffer={selectedOffer}
              />
              <div style={{ display: "none" }}>
                <PrintPDF
                  ref={(el) => (printRef = el)}
                  orderCart={
                    selectedOffer.offers[selectedOffer.offers.length - 1]
                  }
                  printInfo={printInfo}
                  auth={MainStore.auth.user}
                  offerDetails={selectedOffer}
                  totalCartPrice={selectedOffer.withDiscountPrice}
                  nativeCartPrice={selectedOffer.withoutDiscountPrice}
                  currenyUnit={selectedOffer.currenyUnit}
                  current={selectedOffer.current}
                  cartPriceWithoutKdv={selectedOffer.withoutKdvPrice}
                />
              </div>
            </>
          ) : null}
          <Modal
            title="Çıktı Ayarları"
            visible={beforePrint}
            style={{ zIndex: 10000000 }}
            width={400}
            okText={"Çıktı Al"}
            cancelText={"İptal Et"}
            onOk={() => printIt()}
            onCancel={() => setBeforePrint(false)}
          >
            <div style={{ width: "100%" }}>
              <Checkbox
                style={{ width: "100%" }}
                value={printInfo.hide}
                onChange={(value) =>
                  setPrintInfo({ ...printInfo, hide: !printInfo.hide })
                }
              >
                İskonto oranı ve iskonto tutarını gizle
              </Checkbox>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              Tipi:
              <Select
                style={{ width: "100%", marginLeft: 15 }}
                value={printInfo.type}
                placeholder={"Çıktı Tipi"}
                onSelect={(v) => setPrintInfo({ ...printInfo, type: v })}
                options={[
                  {
                    label: "İHRACAT",
                    value: "İHRACAT",
                  },
                  {
                    label: "YURTİÇİ",
                    value: "YURTİÇİ",
                  },
                  {
                    label: "İHRAÇ KAYITLI",
                    value: "İHRAÇ KAYITLI",
                  },
                  {
                    label: "DİĞER",
                    value: "DİĞER",
                  },
                ]}
                onChange={(value) =>
                  setPrintInfo({ ...printInfo, hide: !printInfo.hide })
                }
              >
                İskonto oranı ve iskonto tutarını gizle
              </Select>
            </div>
            {console.log(printInfo.type2)}
            {printInfo.type === "İHRACAT" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                Kullanılacak Hesap:
                <Select
                  value={printInfo.type2}
                  style={{ width: "100%", marginLeft: 15 }}
                  onChange={(value) =>
                    setPrintInfo({ ...printInfo, type2: value })
                  }
                  options={[
                    {
                      label: "LETA PROJE",
                      value: "LETA PROJE",
                    },
                    {
                      label: "ÖZCANLAR",
                      value: "ÖZCANLAR",
                    },
                    {
                      label: "SEÇİM YOK",
                      value: "SEÇİM YOK",
                    },
                  ]}
                />
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              Dil:
              <Select
                value={printInfo.lang}
                style={{ width: "100%", marginLeft: 15 }}
                onChange={(value) =>
                  setPrintInfo({ ...printInfo, lang: value })
                }
              >
                <Select.Option value={"tr"}>Türkçe</Select.Option>
                <Select.Option value={"en"}>İngilizce</Select.Option>
              </Select>
            </div>
          </Modal>
        </div>
      );
  })
);

export default OldOffers;
