import React, { useEffect, useState } from "react";
import { Modal, Input, Select, Button } from "antd";
import styles from "./productmodal.module.css";
import APIKit from "../../shared/APIKit";
import { useSelector } from "react-redux";
import { inject, observer } from "mobx-react";
import { specificField } from "../../utils/specificField";

const ProductModal = inject("MainStore")(
  observer(
    ({
      isOpen,
      confLoading,
      setConfLoading,
      setIsOpen,
      product,
      setItemProps,
      handleCartItemUpdate,
      MainStore,
    }) => {
      const [loading, setLoading] = React.useState(true);
      const [visibleKumasSec, setVisibleKumasSec] = React.useState(false);
      const [selectedKumas, setSelectKumas] = useState({
        tip: "",
        grup: "",
      });
      const { Option } = Select;

      const handleConfigurations = React.useCallback(() => {
        APIKit.post("/api/product/configurations", {
          stkno: product.stkno,
        })
          .then(({ data }) => {
            const itemChanged = {
              ...product,
              configuration: { specs: data.specs },
            };

            handleCartItemUpdate(itemChanged);
            if (
              MainStore.kumas[0] ||
              MainStore.boya1[0] ||
              MainStore.boya2[0] ||
              MainStore.boya3[0] ||
              MainStore.agactip[0] ||
              MainStore.logo[0] ||
              MainStore.kumasKategoriler[0]
            ) {
              setConfLoading(false);
              setLoading(false);
            } else {
              Promise.all([
                APIKit.get("/api/specs"),
                APIKit.post("/api/specs/renk", {
                  stkno: product.stkno,
                }),
                APIKit.get("/api/specs/kumas-kat"),
              ])
                .then(([kumas, renk, kats]) => {
                  MainStore.setKumas(kumas.data);
                  MainStore.setOtherSpecs(renk.data);
                  MainStore.setKumasKategoriler(kats.data);
                  setConfLoading(false);
                  setLoading(false);
                })
                .catch((err) => {
                  setConfLoading(false);
                  setLoading(false);
                });
            }
          })
          .catch((e) => {
            setConfLoading(false);
            setLoading(false);
          });
      }, [handleCartItemUpdate, product]);

      React.useEffect(() => {
        if (isOpen) {
          if (!product.configuration || Object.keys(product.configuration)[0]) {
            setConfLoading(true);
            setLoading(true);
            handleConfigurations();
          }
        } else if (
          product.configuration &&
          Object.keys(product.configuration)[0]
        ) {
          setConfLoading(false);
          setLoading(false);
        }
      }, [isOpen, setIsOpen]);

      return (
        <Modal
          title="Ürünü Özelleştir"
          visible={isOpen}
          width={
            !product?.hasOwnProperty("isNew") && !product.isNew ? 800 : 800
          }
          onCancel={() => setIsOpen(false)}
          footer={false}
        >
          {loading ? (
            <div>Yükleniyor</div>
          ) : (
            <>
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.element}>
                    <label className={styles.label}>Ağaç Tipi : </label>
                    <Select
                      value={product.agactipi}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const itemChanged = {
                          ...product,
                          agactipi: value,
                        };
                        setItemProps(itemChanged);
                        // updateRedux();
                        handleCartItemUpdate(itemChanged);
                      }}
                      showSearch={true}
                      className={styles.select}
                    >
                      {MainStore.agactip
                        ? MainStore.agactip.map((conf, i) => {
                            if (conf)
                              return (
                                <Option key={i} value={conf}>
                                  {conf}
                                </Option>
                              );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className={styles.element}>
                    <label className={styles.label}>Boya Rengi 1 : </label>
                    <Select
                      value={product.boya1}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const itemChanged = {
                          ...product,
                          boya1: value,
                        };
                        setItemProps(itemChanged);
                        handleCartItemUpdate(itemChanged);
                      }}
                      showSearch={true}
                      className={styles.select}
                    >
                      {MainStore.boya1[0]
                        ? MainStore.boya1.map((conf, i) => {
                            if (conf)
                              return (
                                <Option key={i} value={conf}>
                                  {conf}
                                </Option>
                              );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className={styles.element}>
                    <label className={styles.label}>Boya Rengi 2 : </label>
                    <Select
                      value={product.boya2}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const itemChanged = {
                          ...product,
                          boya2: value,
                        };
                        setItemProps(itemChanged);
                        handleCartItemUpdate(itemChanged);
                      }}
                      showSearch={true}
                      className={styles.select}
                    >
                      {MainStore.boya2[0]
                        ? MainStore.boya2.map((conf, i) => {
                            if (conf)
                              return (
                                <Option key={i} value={conf}>
                                  {conf}
                                </Option>
                              );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className={styles.element}>
                    <label className={styles.label}>Logo : </label>
                    <Select
                      value={"LETA"}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const itemChanged = {
                          ...product,
                          logo: value,
                        };
                        setItemProps(itemChanged);
                        handleCartItemUpdate(itemChanged);
                      }}
                      disabled={true}
                      showSearch={true}
                      defaultValue={"LETA"}
                      className={styles.select}
                    >
                      {MainStore.logo
                        ? MainStore.logo?.map((conf, i) => {
                            if (conf)
                              return (
                                <Option key={i} value={conf}>
                                  {conf}
                                </Option>
                              );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className={styles.element}>
                    <label className={styles.label}>Boya Rengi 3 : </label>
                    <Select
                      value={product.boya3}
                      onChange={(value) => {
                        const itemChanged = {
                          ...product,
                          boya3: value,
                        };
                        setItemProps(itemChanged);
                        handleCartItemUpdate(itemChanged);
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch={true}
                      className={styles.select}
                    >
                      {MainStore.boya3
                        ? MainStore.boya3.map((conf, i) => {
                            if (conf)
                              return (
                                <Option key={i} value={conf}>
                                  {conf}
                                </Option>
                              );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className={styles.element}>
                    <Button
                      onClick={() => {
                        setVisibleKumasSec(!visibleKumasSec);
                      }}
                      style={{ marginLeft: 15 }}
                    >
                      Kumaş sınıfı ve kategori seç
                    </Button>
                  </div>
                </div>
                <>
                  <div className={styles.right}>
                    <div className={styles.element}>
                      <label className={styles.label}>Kombinasyonlar : </label>
                      <Select
                        value={product.combination}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch={true}
                        onChange={(value) => {
                          const itemChanged = {
                            ...product,
                            combination: value,
                            combinationData: {},
                          };
                          setItemProps(itemChanged);
                          handleCartItemUpdate(itemChanged);
                        }}
                        className={styles.select}
                      >
                        {product.configuration
                          ? product.configuration.specs
                            ? Object.entries(product.configuration?.specs)?.map(
                                (spec, i) => {
                                  return (
                                    <Option key={i} value={spec[0]}>
                                      {spec[0]}
                                    </Option>
                                  );
                                }
                              )
                            : null
                          : null}
                      </Select>
                    </div>

                    {product.configuration
                      ? product.configuration.specs
                        ? Object.entries(product.configuration?.specs).map(
                            (spec, inx) => {
                              if (spec[0] === product.combination)
                                return spec[1].map((realspec, i) => {
                                  return (
                                    <div key={i} className={styles.element}>
                                      <label className={styles.label}>
                                        {realspec}
                                      </label>

                                      <Select
                                        style={{ maxWidth: 250 }}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => {
                                          const itemChanged = {
                                            ...product,
                                            combinationData: {
                                              ...product.combinationData,
                                              ["c" + i]: {
                                                label: realspec,
                                                value: value,
                                                name: MainStore?.kumas?.find(
                                                  (data) => data.stkno === value
                                                ).stokadi,
                                              },
                                            },
                                          };
                                          setItemProps(itemChanged);
                                          handleCartItemUpdate(itemChanged);
                                        }}
                                        value={
                                          typeof product?.combinationData !==
                                          "undefined"
                                            ? product?.combinationData["c" + i]
                                                ?.value
                                            : ""
                                        }
                                        showSearch={true}
                                        className={styles.select}
                                      >
                                        {product.configuration
                                          ? MainStore.kumas[0]
                                            ? Object.entries(
                                                MainStore.kumas
                                              ).map((spec, i) => {
                                                return (
                                                  <Option
                                                    key={i}
                                                    value={spec[1].stkno}
                                                  >
                                                    {spec[1].stokadi}
                                                  </Option>
                                                );
                                              })
                                            : null
                                          : null}
                                      </Select>
                                    </div>
                                  );
                                });
                            }
                          )
                        : null
                      : null}
                  </div>
                </>
              </div>
              {visibleKumasSec && (
                <div
                  style={{
                    width: 350,
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                      height: 200,
                      borderRight: "1px solid #ccc",
                      marginRight: 20,
                    }}
                  >
                    <h4>KATEGORİ</h4>
                    {specificField(MainStore.kumasKategoriler, "kategori").map(
                      (kategori, i) => {
                        return (
                          <label
                            onClick={() => {
                              const itemChanged = {
                                ...product,
                                kumasKat: kategori,
                              };
                              setItemProps(itemChanged);
                              handleCartItemUpdate(itemChanged);
                            }}
                            key={i}
                            style={{
                              width: 60,
                              padding: 5,
                              fontWeight: 600,
                              fontSize: 16,
                              cursor: "pointer",
                              backgroundColor:
                                product.kumasKat === kategori ? "#ccc" : "",
                            }}
                          >
                            {kategori}
                          </label>
                        );
                      }
                    )}
                  </div>
                  {specificField(MainStore.kumasKategoriler, "grup").map(
                    (grup, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "35%",
                          }}
                        >
                          <h4>{grup.toUpperCase()}</h4>

                          {specificField(
                            MainStore.kumasKategoriler,
                            "tipi"
                          ).map((tip, i) => {
                            return (
                              <label
                                onClick={() => {
                                  const itemChanged = {
                                    ...product,
                                    kumasTip: tip,
                                    kumasGrup: grup,
                                  };

                                  setSelectKumas({
                                    kumasTip: tip,
                                    kumasGrup: grup,
                                  });

                                  setItemProps(itemChanged);
                                  handleCartItemUpdate(itemChanged);

                                  const isSelected =
                                    MainStore.kumasKategoriler.find(
                                      (kumasKat) => {
                                        if (
                                          kumasKat.tipi === tip &&
                                          kumasKat.grup === grup &&
                                          kumasKat.kategori === product.kumasKat
                                        ) {
                                          return kumasKat;
                                        }
                                      }
                                    );

                                  console.log(isSelected);

                                  if (isSelected) {
                                    const itemChanged = {
                                      ...product,
                                      kumasFarki: isSelected.fiyat,
                                    };
                                    setItemProps(itemChanged);
                                    handleCartItemUpdate(itemChanged);
                                  }
                                }}
                                key={i}
                                style={{
                                  width: 60,
                                  padding: 5,
                                  fontWeight: 600,
                                  fontSize: 16,
                                  cursor: "pointer",
                                  backgroundColor:
                                    selectedKumas.kumasTip === tip &&
                                    selectedKumas.kumasGrup === grup
                                      ? "#ccc"
                                      : "",
                                }}
                              >
                                {tip}
                              </label>
                            );
                          })}
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </>
          )}
        </Modal>
      );
    }
  )
);

export default ProductModal;
