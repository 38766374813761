import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

const PrivateRoute = inject("MainStore")(
  observer(({ component: Component, auth, MainStore, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          MainStore.auth.isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  })
);

export default PrivateRoute;
