import React from "react";
import styles from "./product.module.css";
import { FcRemoveImage } from "react-icons/fc";

const Product = ({
  product,
  index,
  setIsOpen,
  setSelectedProduct,
  refresh,
  setRefresh,
}) => {
  const [error, setError] = React.useState(false);
  return (
    <button
      className={styles.product}
      onClick={() => {
        product["index"] = index;
        setSelectedProduct(product);
        setIsOpen(true);
        setRefresh(!refresh);
      }}
    >
      {!error ? (
        <img
          className={styles.img}
          onError={(err) => {
            setError(true);
          }}
          alt={product.stkno}
          src={
            product.images
              ? Object.keys(product.images).length !== 0
                ? product.images[0].url
                : ""
              : ""
          }
        />
      ) : (
        <div className={styles.erroricon}>
          <FcRemoveImage className={styles.icon} />
        </div>
      )}

      <h3 className={styles.text}>{product.stokadi}</h3>
      <h3 className={styles.details}>Teklife Ekle</h3>
    </button>
  );
};

export default Product;
