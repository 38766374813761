import React from "react";
import APIKit from "../../shared/APIKit";
import FlatList from "flatlist-react";
import Product from "../../components/product/Product";
import ReactLoading from "react-loading";
import styles from "./products.module.css";
import ProductImageModal from "../../components/productImageModal/ProductImageModal";
import { Button, Checkbox, Input, Select } from "antd";
import { AiOutlineReload } from "react-icons/ai";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import ReactPageScroller from "react-page-scroller";
import { disableBodyScroll } from "body-scroll-lock";
import { inject, observer } from "mobx-react";

const Products = inject("MainStore")(
  observer(({ countDown, MainStore }) => {
    const [loading, setLoading] = React.useState(true);
    const [filteredProducts, setFilteredProducts] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [groups, setGroups] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [hasMoreItems, setHasMoreItems] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [isSpecial, setIsSpecial] = React.useState(false);
    const [totalProductCount, setTotalProductCount] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const mainRef = React.useRef();

    const setFilter = (search) => {
      var newFiltered = {};

      if (search) {
        setLoading(true);
        APIKit.post("/api/product?skip=0", {
          search: search,
          isSpecial: isSpecial,
        }).then(({ data }) => {
          setFilteredProducts(data);
          setHasMoreItems(true);
          setLoading(false);
        });
      } else {
        setFilteredProducts(MainStore.products);

        setHasMoreItems(true);
      }
    };

    const getMoreFilteredProduct = (search) => {
      var a = 0;
      Object.values(filteredProducts).map((group) => {
        a += group.length;
      });

      APIKit.post(`/api/product?skip=${a}`, {
        search: search,
        isSpecial: isSpecial,
      }).then(({ data }) => {
        let newFiltered = { ...filteredProducts };

        Object.entries(data).map((value) => {
          var a = newFiltered[value[0]] ? newFiltered[value[0]] : [];

          value[1].map((prd) => {
            a.push(prd);
          });

          newFiltered[value[0]] = a;
        });

        setFilteredProducts(newFiltered);
        setHasMoreItems(true);
        setLoading(false);
      });
    };

    const loadData = () => {
      if (search) {
        getMoreFilteredProduct(search);
      } else {
        APIKit.get(
          `/api/product?skip=${MainStore.getTotalProductCount()}&isSpecial=${isSpecial},`
        ).then(({ data }) => {
          MainStore.addProduct(data);
          setFilteredProducts(MainStore.products);
        });
      }
    };

    React.useEffect(() => {
      disableBodyScroll(mainRef.current);
      if (Object.keys(MainStore.products).length) {
        setFilteredProducts(MainStore.products);
        setLoading(false);
      } else if (!filteredProducts[0])
        APIKit.get(`/api/product?skip=${MainStore.products.length}`).then(
          ({ data }) => {
            MainStore.setProducts(data);
            setFilteredProducts(data);

            setLoading(false);
          }
        );
    }, []);

    const refreshFilteredData = () => {
      setFilteredProducts(MainStore.products);
      setRefresh(!refresh);
    };

    const handleNewProducts = async () => {
      const { data } = await APIKit.get("/api/product/handle-new-products");
    };

    if (loading)
      return (
        <div className={styles.centeredDiv}>
          <ReactLoading
            type={"balls"}
            color={"var(--primary-color)"}
            height={300}
            width={150}
          />
        </div>
      );
    else
      return (
        <div className={styles.topContainer}>
          <div className={styles.headerFilter}>
            <Input
              onChange={(e) => {
                setSearch(e.target.value);
                if (!e.target.value) {
                  setFilteredProducts(MainStore.products);
                  setHasMoreItems(true);
                }
              }}
              value={search}
              placeholder={"Ara..."}
            />
            <Button onClick={() => setFilter(search)}>Ara</Button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: 150,
              }}
            >
              <label>Özel</label>
              <Checkbox
                checked={isSpecial}
                onChange={(value) => setIsSpecial(!isSpecial)}
              />
            </div>

            {/* 
            <Select
              value={currentPage}
              className={styles.select}
              onChange={(value) => {
                setCurrentPage(parseInt(value));
              }}
            >
              {Object.keys(filteredProducts).map((value, i) => {
                return (
                  <Select.Option value={i} key={i}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
            */}

            <Button onClick={() => handleNewProducts()} className={styles.load}>
              <AiOutlineReload />
            </Button>

            {/* 
            <Button
              className={styles.rightButton}
              onClick={() => {
                if (currentPage !== 0) setCurrentPage(currentPage - 1);
              }}
            >
              <AiFillCaretUp />
            </Button>
            <Button
              className={styles.rightButton}
              onClick={() => {
                if (Object.keys(filteredProducts).length > currentPage)
                  setCurrentPage(currentPage + 1);
              }}
            >
              <AiFillCaretDown />
            </Button>
            */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {filteredProducts && Object.keys(filteredProducts).length > 0 ? (
              <FlatList
                list={Object.values(filteredProducts)}
                renderItem={(group, index) => {
                  return group.map((product, i) => {
                    return (
                      <Product
                        key={product.stkno}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        product={product}
                        index={index}
                        setIsOpen={setIsOpen}
                        setSelectedProduct={setSelectedProduct}
                      />
                    );
                  });
                }}
                hasMoreItems={hasMoreItems}
                loadMoreItems={loadData}
                paginationLoadingIndicator={
                  <div
                    style={{
                      background: "#343434",
                      position: "absolute",
                      bottom: 0,
                      width: "100vw",
                      height: 50,
                      zIndex: 1000,
                      left: 0,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Ürünler yükleniyor...
                  </div>
                }
              />
            ) : (
              <div>Böyle bir ürün bulunamadı</div>
            )}
          </div>

          {/*<ReactPageScroller
            blockScrollUp={true}
            blockScrollDown={true}
            customPageNumber={currentPage}
            animationTimer={200}
            ref={mainRef}
          >
            {Object.keys(filteredProducts).length ? (
              Object.entries(filteredProducts).map((grouped, i) => {
                return (
                  <div key={i} className={styles.mainContainer}>
                    <h2>{grouped[0]}</h2>
                    <div className={styles.container}>
                      <FlatList
                        srollToTop
                        list={grouped[1]}
                        renderItem={(product, index) => (
                          <Product
                            refresh={refresh}
                            setRefresh={setRefresh}
                            key={index}
                            product={product}
                            index={index}
                            setIsOpen={setIsOpen}
                            setSelectedProduct={setSelectedProduct}
                          />
                        )}
                        renderOnScroll
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Böyle bir ürün bulunamadı</div>
            )}
            </ReactPageScroller>*/}
          {isOpen && (
            <ProductImageModal
              refresh={refresh}
              setRefresh={setRefresh}
              refreshFilteredData={refreshFilteredData}
              isOpen={isOpen}
              setSelectedProduct={setSelectedProduct}
              setIsOpen={setIsOpen}
              selectedProduct={selectedProduct}
              countDown={countDown}
            />
          )}
        </div>
      );
  })
);

export default Products;
