import React from "react";
import styles from "./reports.module.css";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Select } from "antd";
import APIKit from "../../shared/APIKit";
import ReactLoading from "react-loading";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

const Reports = inject("MainStore")(
  observer(({ MainStore }) => {
    const [selectedTime, setSelectedTime] = React.useState(0);
    const [reportData, setReportData] = React.useState({});
    const [malls, setMalls] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [radialData, setRadialData] = React.useState([1, 1, 1, 1, 1]);
    const colors = ["#22215B", "#82B7F6", "#FBC701", "#567DF4", "#93CB4C"];
    const [sellers, setSellers] = React.useState(["", "", "", "", ""]);
    const [selectedMall, setSelectedMall] = React.useState(
      MainStore.auth.user.gorev !== "admin"
        ? MainStore.auth.user.magaza
        : "Basınekspres"
    );

    const [lineData, setLineData] = React.useState({});

    const [chartCategories, setChartCategories] = React.useState([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ]);

    const series = [
      {
        name: "Teklifler",
        data:
          selectedTime === 0
            ? lineData?.day?.values
              ? lineData?.day?.values
              : [0, 0, 0, 0]
            : selectedTime === 1
            ? lineData?.week?.values
              ? lineData?.week?.values
              : [0, 0, 0, 0]
            : selectedTime === 2
            ? lineData?.month?.values
              ? lineData?.month?.values
              : [0, 0, 0, 0]
            : [0, 0, 0, 0],
      },
    ];

    const options = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Oluşturulan Teklifler",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        max: 8,
      },
      xaxis: {
        categories:
          selectedTime === 0
            ? lineData?.day?.hours
              ? lineData?.day?.hours
              : [0, 0, 0, 0]
            : selectedTime === 1
            ? lineData?.week?.days
              ? lineData?.week?.days
              : [0, 0, 0, 0]
            : selectedTime === 2
            ? lineData?.month?.days
              ? lineData?.month?.days
              : [0, 0, 0, 0]
            : [0, 0, 0, 0],
      },
    };

    const compare = (a, b) => {
      if (parseInt(a.value) < parseInt(b.value)) {
        return 1;
      }
      if (parseInt(a.value) > parseInt(b.value)) {
        return -1;
      }
      return 0;
    };

    //Chart Üzerinde isimlendirme verilecek
    const optionsRadial = {
      chart: {
        type: "donut",
      },
      label: {
        show: false,
      },

      tooltip: {
        enabled: false,
      },
      labels: sellers,
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      legend: {
        show: false,
      },
    };

    const getNewReports = (value) => {
      APIKit.post("api/reports/", { magaza: value })
        .then(({ data }) => {
          setReportData(data);
          setLineData(data.lineData);
          var a = [];
          var c = 0;

          data?.sellerReports
            ?.sort(compare)
            .slice(0, 5)
            .map((seller, i) => {
              if (seller.value === 0) c++;
              a.push(seller.value);
            });

          if (
            c === 5 ||
            data?.sellerReports?.sort(compare).slice(0, 5).length === c
          ) {
            var g = [];
            var b = [];
            data?.sellerReports
              ?.sort(compare)
              .slice(0, 5)
              .map((sel, i) => {
                g.push(1);
                b.push(sel.label);
              });
            setRadialData(g);
            setSellers(b);
          } else setRadialData(a);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    React.useEffect(() => {
      APIKit.get("api/mall/").then(({ data }) => {
        setMalls(data);
        getNewReports(selectedMall);
      });
    }, []);

    const PlasiyerItem = ({ seller, color }) => {
      return (
        <Link
          to={`/home/offerlist?plasiyer=${seller.label}`}
          className={styles.item}
        >
          <div className={styles.col}>
            <div
              className={styles.collapse}
              style={{ backgroundColor: color }}
            />
          </div>
          <div className={styles.col}>
            <h3>{seller.label}</h3>
            <span>{seller.value} Teklif</span>
          </div>
          <div className={styles.col}>
            <div
              className={styles.line}
              style={{
                background: `linear-gradient(to left, ${color} ${
                  seller.value * 10
                }%, #EEF7FE ${seller.value * 10 - 100}%)`,
              }}
            ></div>
          </div>
        </Link>
      );
    };

    if (loading)
      return (
        <div className={styles.centeredDiv}>
          <ReactLoading
            type={"balls"}
            color={"var(--primary-color)"}
            height={300}
            width={150}
          />
        </div>
      );
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.top}>
            <Link
              to={"/home/offerlist?magaza=" + selectedMall}
              className={styles.item}
            >
              <div
                className={styles.oval}
                style={{ backgroundColor: "rgba(76, 152, 242, 0.7)" }}
              >
                {reportData.toplamTeklifler}
              </div>
              <span>Oluşturulan Teklif Sayısı</span>
            </Link>
            <Link
              to={"/home/offerlist?status=Onaylandı&magaza=" + selectedMall}
              className={styles.item}
            >
              <div
                className={styles.oval}
                style={{ backgroundColor: "rgba(101, 181, 0, 0.7)" }}
              >
                {reportData.onaylananTeklifler}
              </div>
              <span>Onaylanan Teklif</span>
            </Link>
            <Link
              to={"/home/offerlist?status=Reddedilen&magaza=" + selectedMall}
              className={styles.item}
            >
              <div
                className={styles.oval}
                style={{ backgroundColor: "rgba(215, 59, 10, 0.7)" }}
              >
                {reportData.reddedilenTeklifler}
              </div>
              <span>Reddedilen Teklif Sayısı</span>
            </Link>
            <Link
              to={
                "/home/offerlist?status=Onayda Bekleyen&magaza=" + selectedMall
              }
              className={styles.item}
            >
              <div
                className={styles.oval}
                style={{ backgroundColor: "rgba(255, 199, 0, 0.72)" }}
              >
                {reportData.bekleyenTeklifler}
              </div>
              <span>Bekleyen Teklif Sayısı</span>
            </Link>
          </div>
          <div className={styles.bottom}>
            <div className={styles.selectTime}>
              <button
                onClick={() => setSelectedTime(0)}
                style={{
                  backgroundColor: selectedTime === 0 ? "#8DC1FF" : "#D9D7D7",
                }}
              >
                Günlük
              </button>
              <button
                onClick={() => setSelectedTime(1)}
                style={{
                  backgroundColor: selectedTime === 1 ? "#8DC1FF" : "#D9D7D7",
                }}
              >
                Haftalık
              </button>
              <button
                onClick={() => setSelectedTime(2)}
                style={{
                  backgroundColor: selectedTime === 2 ? "#8DC1FF" : "#D9D7D7",
                }}
              >
                Aylık
              </button>
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={"95%"}
              width={"100%"}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.cont}>
            {MainStore.auth.user.gorev === "admin" ? (
              <Select
                value={selectedMall}
                style={{ width: "90%" }}
                onChange={(value) => {
                  setRadialData([]);
                  getNewReports(malls[value].name);
                  setSelectedMall(malls[value].name);
                }}
                placeholder={"Mağaza Seçiniz"}
              >
                {malls.map((mall, i) => {
                  return <Select.Option key={i}>{mall.name}</Select.Option>;
                })}
              </Select>
            ) : null}

            <div className={styles.topcont}>
              <ReactApexChart
                options={optionsRadial}
                series={radialData}
                type="donut"
                height={"95%"}
                width={"100%"}
              />
            </div>
            <div className={styles.content}>
              {reportData?.sellerReports
                ?.sort(compare)
                .slice(0, 5)
                .map((seller, i) => {
                  return <PlasiyerItem seller={seller} color={colors[i]} />;
                })}
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Reports;
