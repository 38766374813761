import React, { useEffect } from "react";
import styles from "./home.module.css";
import Logo from "../../images/letalogo.png";
import LoadingOverlay from "react-loading-overlay";
import {
  NavLink,
  useRouteMatch,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Products from "../products/Products";
import Currents from "../currents/Currents";
import PrivateRoute from "../../components/private-route/PrivateRoute";
import { Menu, Dropdown, Modal, message } from "antd";
import { BiExit } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { BsThreeDots } from "react-icons/bs";
import AddCurrentModal from "../../components/addcurrentmodal/AddCurrentModal";
import OldOffers from "../oldoffers/OldOffers";
import PrintPDF from "../../components/printOfferCart/PrintPDF";
import Reports from "../reports/Reports";
import NewProducts from "../newproducts/NewProducts";
import UserConfiguration from "../../components/userConfiguratin/UserConfiguration";
import OfferCart from "../offerCart/OfferCart";
import { inject, observer } from "mobx-react";
import Drafts from "../../components/drafts/Drafts";
import Loading from "react-loading";

const Home = inject("MainStore")(
  observer((props) => {
    const [newCurrentModal, setNewCurrentModal] = React.useState(false);
    const [userModal, setUserModal] = React.useState(false);
    const [refresh, setRefresh] = React.useState(true);
    const { url, path } = useRouteMatch();
    const [routes, setRoutes] = React.useState([
      {
        path: url + "/products",
        name: "Ürünler",
        render: () => <Products countDown={countDown} />,
      },
      {
        path: url + "/currentlist",
        name: "Cari Listesi",
        render: () => <Currents refresh={refresh} setRefresh={setRefresh} />,
      },
      {
        path: url + "/newproducts",
        name: "Eklenen Ürünler",
        render: () => <NewProducts />,
      },
      {
        path: url + "/drafts",
        name: "Taslaklar",
        render: () => <Drafts />,
      },
      {
        path: url + "/offerlist",
        name: "Teklif Listesi",
        render: () => <OldOffers />,
      },

      {
        path: url + "/offer",
        name: "Teklif",
        render: () => <OfferCart />,
      },
      {
        path: url + "/reports",
        name: "Raporlar",
        render: () => <Reports />,
      },
    ]);
    const { confirm } = Modal;

    useEffect(() => {
      props.MainStore.getDrafts();
    }, []);

    function showConfirm() {
      confirm({
        title: "Çıkış Yapmak İstediğinizden Eminmisiniz?",
        icon: <ExclamationCircleOutlined />,
        okText: "Çıkış Yap",
        cancelText: "Hayır",
        onOk() {
          props.MainStore.logOut();
        },
        onCancel() {},
      });
    }

    function countDown() {
      let secondsToGo = 3;
      /*const modal = Modal.success({
      title: "Önce Cari Seçmelisiniz!",
      content: ``,
      okText: "Tamam",
    });*/
      message.info("Cari seçmelisiniz!");
    }

    const menu = (
      <Menu onClick={() => {}}>
        <Menu.Item key="1" icon={<BiExit />} onClick={() => setUserModal(true)}>
          Kullanıcı İşlemleri
        </Menu.Item>
        <Menu.Item key="2" icon={<FiSettings />} onClick={showConfirm}>
          Çıkış Yap
        </Menu.Item>
      </Menu>
    );

    return (
      <LoadingOverlay
        active={props.MainStore.loading}
        styles={{
          wrapper: {
            overflow: props.MainStore.loading ? "hidden" : "scroll",
          },
        }}
        spinner
        text={"Yükleniyor..."}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <Link to={url + "/home"}>
              <img src={Logo} />
            </Link>
            <div style={{ color: "white" }}>
              {Object.keys(props.MainStore.current).length
                ? "Seçili Cari: " + props.MainStore.current.unvan
                : "Seçili Cari Yok!"}
              <span style={{ marginLeft: 30 }}>
                Magaza : {props.MainStore.auth.user.magaza}
              </span>
              <span style={{ marginLeft: 20 }}>
                Kullanıcı Adı : {props.MainStore.auth.user.username}
              </span>
              <span style={{ marginLeft: 20 }}>
                Görev : {props.MainStore.auth.user.gorev}
              </span>
            </div>
            {props.MainStore.auth.user.gorev === "admin" ||
            props.MainStore.auth.user.gorev === "Müdür" ? (
              <NavLink className={styles.dashboard} to={url + "/reports"}>
                Raporlar
              </NavLink>
            ) : null}

            <button
              onClick={() => setNewCurrentModal(true)}
              className={styles.createCari}
              style={
                props.MainStore.auth.user.gorev !== "admin" &&
                props.MainStore.auth.user.gorev !== "Müdür"
                  ? {
                      marginLeft: "auto",
                    }
                  : null
              }
            >
              Cari Oluştur
            </button>
            <Dropdown overlay={menu} className={styles.exit}>
              <BsThreeDots width={20} height={20} />
            </Dropdown>
          </div>
          <div className={styles.subHeader}>
            {routes.map((route, i) => {
              if (route.name !== "Raporlar")
                return (
                  <NavLink
                    key={i}
                    className={styles.link}
                    activeClassName={styles.activeLink}
                    to={route.path}
                  >
                    {route.name}
                    <div className={styles.icon}>
                      {Object.keys(props.MainStore.offerCart).length}
                    </div>
                    <div
                      className={styles.icon}
                      style={{
                        right: "36.5%",
                      }}
                    >
                      {props.MainStore.loadingDrafts ? (
                        <div
                          style={{
                            marginTop: 0,
                          }}
                        >
                          <Loading
                            type="spin"
                            height={20}
                            width={20}
                            color="#343434"
                          />
                        </div>
                      ) : (
                        Object.keys(props.MainStore.drafts).length
                      )}
                    </div>
                  </NavLink>
                );
            })}
          </div>
          <div className={styles.content}>
            <Switch>
              {routes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  component={route.render}
                />
              ))}
              <Redirect to={path + "/products"} />
            </Switch>
          </div>
        </div>
        <UserConfiguration isOpen={userModal} setIsOpen={setUserModal} />
        <AddCurrentModal
          setIsOpen={setNewCurrentModal}
          isOpen={newCurrentModal}
        />
      </LoadingOverlay>
    );
  })
);

export default Home;
