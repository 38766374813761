const specificField = (array, field) => {
  let result = {};

  array.forEach((item) => {
    result[item[field]] = "";
  });

  result = Object.keys(result);

  return result;
};

export { specificField };
