import React from "react";
import APIKit from "../../shared/APIKit";
import { numberToMoney } from "../../utils/util";
import "./newPrint.module.css";

const NewPdf = ({ items, lang, hide, currenyUnit }) => {
  const floatParser = (data) => {
    if (parseFloat(data)) return parseFloat(data);
    else return 0;
  };

  const otherName = (stkno) => {
    if (stkno) {
      APIKit.get(`/api/product/otherName/${stkno}`).then(({ data }) => {
        return data;
      });
    }
  };

  return (
    <table>
      <tr>
        <th>
          N<br />O
        </th>
        <th>{lang === "tr" ? "ÜRÜN GÖRSELİ" : "IMAGE"}</th>
        <th>{lang === "tr" ? "ÜRÜN ADI" : "PRODUCT CODE"}</th>
        <th>{lang === "tr" ? "TEKNİK AÇIKLAMA" : "TECHNICAL INFORMATION"}</th>
        <th>{lang === "tr" ? "AHŞAP/METAL DETAYI" : "WOOD/METAL DETAILS"}</th>
        <th>
          {lang === "tr" ? "KUMAŞ/DERİ DETAYI" : "FABRIC/PU LEATHER DETAILS"}
        </th>
        <th>{lang === "tr" ? "ADET" : "QTY"}</th>
        {!hide && (
          <>
            <th>{lang === "tr" ? "LİSTE BİRİM FİYATI" : "UNIT PRICE"}</th>
            <th>{lang === "tr" ? "İSK.ORANI" : "DISCOUNT RATE"}</th>
          </>
        )}
        <th>{lang === "tr" ? "İSKONTOLU BİRİM FİYATI" : "DISCOUNTED PRICE"}</th>
        <th>{lang === "tr" ? "BİRİM KUMAŞ FARKI" : "FABRIC SURCHARGE"}</th>
        <th>{lang === "tr" ? "TOPLAM" : "LINE TOTAL"}</th>
      </tr>
      {items.map((order, index) => {
        return (
          <>
            <tr key={index}>
              <td>{index + 1}</td>
              <td
                style={{
                  width: 100,
                  minWidth: 100,
                }}
              >
                <img
                  alt={index}
                  src={order.images[0] ? order.images[0]["url"] : ""}
                  style={{ maxHeight: "90%", maxWidth: "90%" }}
                />
              </td>
              <td>{lang === "tr" ? order.stokadi : order.stokadi2}</td>
              <td>{order.aciklama}</td>
              <td>{order.ahsapmetal}</td>
              <td>{order.derikumas}</td>
              <td>{order.count}</td>
              {!hide && (
                <>
                  <td>{numberToMoney(order.price, currenyUnit)}</td>

                  <td>
                    {isNaN(
                      parseFloat(
                        ((floatParser(order.price) -
                          ((100 - floatParser(order.discount3)) / 100) *
                            ((100 - floatParser(order.discount2)) / 100) *
                            ((100 - floatParser(order.discount1)) / 100) *
                            (floatParser(order.price) -
                              floatParser(order.totalDiscount))) /
                          floatParser(order.price)) *
                          100
                      ).toFixed(2)
                    )
                      ? 0
                      : parseFloat(
                          ((floatParser(order.price) -
                            ((100 - floatParser(order.discount3)) / 100) *
                              ((100 - floatParser(order.discount2)) / 100) *
                              ((100 - floatParser(order.discount1)) / 100) *
                              (floatParser(order.price) -
                                floatParser(order.totalDiscount))) /
                            floatParser(order.price)) *
                            100
                        ).toFixed(2)}
                    %
                  </td>
                </>
              )}

              <td
                style={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  padding: 2,
                }}
              >
                {numberToMoney(
                  ((100 - order.discount3) / 100) *
                    ((100 - order.discount2) / 100) *
                    ((100 - order.discount1) / 100) *
                    (order.price - order.totalDiscount),
                  currenyUnit
                )}
              </td>

              <td>
                {numberToMoney(floatParser(order.kumasFarki), currenyUnit)}
              </td>
              <td>
                {numberToMoney(
                  ((100 - order.discount3) / 100) *
                    ((100 - order.discount2) / 100) *
                    ((100 - order.discount1) / 100) *
                    order.price *
                    order.count -
                    order.totalDiscount +
                    floatParser(order.kataForez) * order.count +
                    floatParser(order.kumasFarki) * order.count,
                  currenyUnit
                )}
              </td>
            </tr>
            <div
              style={{
                height: index === 5 || (index - 5) % 8 === 0 ? 40 : 0,
                display:
                  index === 5 || (index - 5) % 8 === 0 ? "block" : "none",
              }}
            />
          </>
        );
      })}
    </table>
  );
};

export default NewPdf;
