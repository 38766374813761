import React, { useState } from "react";
import { Modal, Button, Form, Input, Col, Row, Select, Divider } from "antd";
import styles from "./addcurrentmodal.module.css";
import { TiPlusOutline } from "react-icons/ti";
import APIKit from "../../shared/APIKit";
import { Upload, Checkbox, message } from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import s3Config from "../../config/s3Config";
import S3 from "react-aws-s3";
import { BiX } from "react-icons/bi";
import { useAlert } from "react-alert";
import { SET_LOADING } from "../../actions/types";
import { inject, observer } from "mobx-react";
import ProjectModal from "./ProjectModal";

const AddCurrentModal = inject("MainStore")(
  observer(({ setIsOpen, isOpen, MainStore }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectItems, setSelectItems] = React.useState({
      status: [{ label: "test", value: "test" }],
      sipCat: [{ label: "test", value: "test" }],
      sevkCat: [{ label: "test", value: "test" }],
      specDef: [{ label: "test", value: "test" }],
      ulke: [],
      il: [],
      mustips: [],
      ilce: [],
      currencyUnit: [
        { label: "TL", value: "TL" },
        { label: "USD", value: "USD" },
        { label: "EURO", value: "EURO" },
      ],
    });
    const [ulke, setUlkem] = useState("");
    const [newName, setNewName] = useState("");
    const [files, setFiles] = useState([]);
    const [currencyUnit, setCurrenyUnit] = useState("");
    const [uniqueTime, setUniqueTime] = useState("");
    const [projects, setProjects] = useState([]);
    const [form] = Form.useForm();
    const [isReal, setIsReal] = useState(false);
    const alert = useAlert();

    const onFinish = (values) => {
      MainStore.setLoading(true);
      if (!isReal) {
        values["ad"] = "";
        values["soyad"] = "";
        values["tcNo"] = "";
      } else {
        values["brand"] = "";
        values["taxAdministration"] = "";
        values["unvan"] = "";
      }
      values["currencyUnit"] = currencyUnit;
      values["isReal"] = isReal;
      values["files"] = files;
      values["project"] = projects;

      APIKit.post("api/current/", values)
        .then((res) => {
          setFiles([]);
          form.resetFields();
          MainStore.setLoading(false);
          setProjects([]);
          setIsOpen(false);
          message.success("Cari oluşturuldu!");
        })
        .catch((err) => {
          MainStore.setLoading(false);
          message.error("Cari oluşturulamadı!");
        });
    };

    const props = {
      customRequest({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) {
        const config = {
          bucketName: s3Config.bucketName,
          dirName: "leta/files/",
          region: "eu-central-1",
          accessKeyId: s3Config.accessKeyId,
          secretAccessKey: s3Config.secretAccessKey,
        };

        const ReactS3Client = new S3(config);
        const fileName = Math.floor(Date.now() / 1000) + ".jpg";

        ReactS3Client.uploadFile(file, fileName).then((data) => {
          data["name"] = fileName;
          setFiles([
            ...files,
            {
              uid: data.location,
              name: fileName,
              status: "done",
              url: data.location,
            },
          ]);
          onSuccess(
            {
              uid: data.location,
              name: fileName,
              status: "done",
              url: data.location,
            },
            data
          );
          message.success("Resim başarıyla yüklendi!");
        });
      },
    };

    const handleRemove = (item) => {
      var newFiles = [];
      for (var i = 0; i < files.length; i++) {
        if (files[i].name !== item.xhr.name) {
          newFiles.push(files[i]);
        }
      }
      setFiles(newFiles);
    };

    React.useEffect(() => {
      APIKit.get("/api/current/fields").then(({ data }) => {
        setSelectItems({
          ...selectItems,
          ulke: data.ulkeler,
          currencyUnit: data.paracinsi,
          mustips: data.mustip,
        });
      });

      /*  APIKit.get("/api/location").then(({ data }) => {
        setSelectItems({ ...selectItems, ulke: data });
      });*/
    }, []);

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    /*
    const setUlke = (e) => {
      APIKit.post("/api/location/states", {
        country: e,
      }).then(({ data }) => {
        setSelectItems({ ...selectItems, il: data });
      });
      setUlkem(e);
    };

    const setIl = (e) => {
      APIKit.post("/api/location/cities", {
        country: ulke,
        stateName: e,
      }).then(({ data }) => {
        setSelectItems({ ...selectItems, ilce: data });
      });
    }; */

    const { TextArea } = Input;
    const { Option } = Select;

    const addItem = (d) => {
      if (newName) {
        setSelectItems({
          ...selectItems,
          [d]: [
            ...selectItems[d],
            {
              label: newName,
              value: newName,
            } || `Yeni Ekle!`,
          ],
        });

        setNewName("");
      }
    };

    const removeFromArray = (i) => {
      var newProjects = [];

      projects.map((proje, index) => {
        if (i !== index) {
          newProjects.push(proje);
        }
      });

      setProjects(newProjects);
    };

    const onConfirmProject = (proje) => {
      setProjects([...projects, proje]);
    };

    return (
      <Modal
        title="Cari Ekle"
        centered
        visible={isOpen}
        onCancel={() => {
          setProjects([]);
          form.resetFields();
          setIsOpen(false);
        }}
        width={1150}
        footer={false}
      >
        <Form
          form={form}
          style={{ minWidth: "100%" }}
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              padding: 15,
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <Row className={styles.row}>
              <Col className={styles.col}></Col>
            </Row>
            <Row className={styles.row}>
              {!isReal ? (
                <>
                  <Col className={styles.col}>
                    <Form.Item
                      label="Ünvan"
                      name="unvan"
                      rules={[
                        { required: false, message: "Alan boş bırakılamaz!" },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col className={styles.col}>
                    <Form.Item
                      label="Marka"
                      name="brand"
                      rules={[
                        { required: false, message: "Alan boş bırakılamaz!" },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col className={styles.col}>
                    <Form.Item
                      label="Ad"
                      name="ad"
                      rules={[
                        { required: false, message: "Alan boş bırakılamaz!" },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col className={styles.col}>
                    <Form.Item
                      label="Soyad"
                      name="soyad"
                      rules={[
                        { required: false, message: "Alan boş bırakılamaz!" },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                </>
              )}

              {!isReal ? (
                <>
                  <Col className={styles.col}>
                    <Form.Item label="Vergi Dairesi" name="taxAdministrator">
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col className={styles.col}>
                    <Form.Item label="Vergi Numarası" name="taxNo">
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <Col className={styles.col}>
                  <Form.Item label="Tc Numarası " name="tcNo">
                    <Input className={styles.input} />
                  </Form.Item>
                </Col>
              )}

              <Col className={styles.col}>
                <Form.Item
                  label="Adres"
                  name="address"
                  rules={[
                    { required: false, message: "Alan boş bırakılamaz!" },
                  ]}
                >
                  <TextArea className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              padding: 15,
              border: "1px solid rgba(0,0,0,0.2)",
              marginTop: 15,
            }}
          >
            <Row className={styles.row}>
              <Col className={styles.col}>
                <Form.Item label="Telefon 1" name="phone1">
                  <Input className={styles.input} />
                </Form.Item>
              </Col>
              <Col className={styles.col}>
                <Form.Item label="Telefon 2" name="phone2">
                  <Input className={styles.input} />
                </Form.Item>
              </Col>
              <Col className={styles.col}>
                <Form.Item label="E-Posta" name="email">
                  <Input className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              padding: 15,
              border: "1px solid rgba(0,0,0,0.2)",
              marginTop: 15,
            }}
          >
            <Row className={styles.row}>
              <Col className={styles.col}>
                <Form.Item label="Ülke" name="ulke">
                  <Select
                    showSearch
                    onSelect={(e) => setUlkem(e)}
                    className={styles.select}
                  >
                    {selectItems.ulke.map((item) => (
                      <Option key={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className={styles.col}>
                <Form.Item label="İl" name="il">
                  <Input className={styles.input} />
                </Form.Item>
              </Col>
              <Col className={styles.col}>
                <Form.Item label="İlçe" name="ilce">
                  <Input className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              padding: 15,
              border: "1px solid rgba(0,0,0,0.2)",
              marginTop: 15,
            }}
          >
            <Row className={styles.row}>
              <Col className={styles.col}>
                <Form.Item
                  label="Para Birimi"
                  name="currencyUnit"
                  rules={[
                    { required: false, message: "Alan boş bırakılamaz!" },
                  ]}
                >
                  <Select
                    onSelect={(e) => {
                      setCurrenyUnit(e);
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                          />
                          <a
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={() => addItem("currencyUnit")}
                          >
                            <TiPlusOutline /> Yeni Ekle
                          </a>
                        </div>
                      </div>
                    )}
                    className={styles.select}
                  >
                    {selectItems.currencyUnit.map((item) => (
                      <Option key={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className={styles.col}>
                <Form.Item
                  label="Müşteri Tipi"
                  name="mustip"
                  rules={[
                    { required: false, message: "Alan boş bırakılamaz!" },
                  ]}
                >
                  <Select
                    onSelect={(e) => {
                      setCurrenyUnit(e);
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                          />
                          <a
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={() => addItem("currencyUnit")}
                          >
                            <TiPlusOutline /> Yeni Ekle
                          </a>
                        </div>
                      </div>
                    )}
                    className={styles.select}
                  >
                    {selectItems.mustips.map((item) => (
                      <Option key={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ position: "relative" }}>
              <Col>
                <Upload
                  {...props}
                  onRemove={handleRemove}
                  listType="picture"
                  maxCount={3}
                  multiple
                >
                  <Button
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: 30,
                    }}
                    icon={<AiOutlineUpload />}
                  >
                    <span style={{ marginLeft: 10 }}>Dosya Ekle (Max: 3)</span>
                  </Button>
                </Upload>
              </Col>
              <Col
                onClick={() => setIsModalOpen(true)}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <Button>Proje Ekle</Button>
              </Col>
              <Col style={{ bottom: 60 }}>
                {projects.map((proje, i) => (
                  <div
                    key={i}
                    style={{
                      width: 200,
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                      marginBottom: 5,
                      padding: 5,
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>Proje Adı: {proje.vprojead} </label>
                    <button
                      type={"button"}
                      onClick={() => removeFromArray(i)}
                      style={{
                        backgroundColor: "transparent",
                        border: 0,
                        cursor: "pointer",
                      }}
                    >
                      <BiX size={25} />
                    </button>
                  </div>
                ))}
              </Col>
            </Row>
          </div>

          <div style={{ padding: 10, width: "100%", display: "flex" }}>
            <Button
              type="primary"
              style={{
                marginLeft: "auto",
                width: 170,
                borderRadius: 15,
                backgroundColor: "var(--secondary-color)",
                border: 0,
              }}
              htmlType="submit"
            >
              Onayla
            </Button>
          </div>
        </Form>

        <ProjectModal
          onConfirmProject={onConfirmProject}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      </Modal>
    );
  })
);

export default AddCurrentModal;
