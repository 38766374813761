import React from "react";
import { Button, Input, message, Modal } from "antd";
import APIKit from "../../../shared/APIKit";
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import LoadingOverlay from "react-loading-overlay";

const OfferConfirmation = inject("MainStore")(
  observer(
    ({
      isOpen,
      setIsOpen,
      current,
      offer,
      setTopOpen,
      loadData,
      MainStore,
      status,
      setConfirmProject,
      setProject,
    }) => {
      const [loading, setLoading] = React.useState(true);
      const [projects, setProjects] = React.useState([]);
      const [selectedProject, setSelectedProject] = React.useState(null);
      const [newProjectName, setNewProjectName] = React.useState(
        offer.offerName
      );
      const [loadingOverlay, setLoadingOverlay] = React.useState(false);
      const formikRef = React.useRef();

      const createOffer = () => {
        setLoadingOverlay(true);
        APIKit.post("api/offer/status", {
          offerId: offer._id,
          status: "Onaylandı (Projesiz)",
          newProjectName: newProjectName,
        })
          .then((res) => {
            message.success("Teklif Onaylandı.");
            setLoading(true);
            loadData();
            setLoadingOverlay(false);
            setIsOpen(false);
            setProject(false);
            setTopOpen(false);
          })
          .catch((err) => {
            if (err?.response?.data) message.error(err.response.data);
            else message.error("Teklif onaylanamadı!");
            setLoadingOverlay(false);
          });
      };

      const createOfferWithProject = () => {
        if (selectedProject === null) message.info("Proje seçmelisiniz!");
        else if (projects[selectedProject].projeno) {
          setLoadingOverlay(true);
          APIKit.post("api/offer/status", {
            offerId: offer._id,
            status: "Onaylandı (Projeli)",
            projectNo: projects[selectedProject].projeno,
            newProjectName: newProjectName,
          })
            .then((res) => {
              message.success("Teklif Onaylandı.");
              setLoading(false);
              setLoadingOverlay(false);
              loadData();
              setIsOpen(false);
              setProject(false);
              setTopOpen(false);
            })
            .catch((err) => {
              if (err?.response?.data) message.error(err.response.data);
              else message.error("Teklif onaylanamadı!");
              setLoadingOverlay(false);
            });
        } else {
          message.error("Proje Seçmelisiniz!");
        }
      };

      React.useEffect(() => {
        setSelectedProject(null);
        if (isOpen) {
          setLoading(true);
          APIKit.post("/api/project", {
            vhesap: current.hesap,
          }).then(({ data }) => {
            setProjects(data);

            setLoading(false);
          });
        }
      }, [isOpen, setIsOpen]);

      return (
        <Modal
          title="Proje Aşaması"
          visible={isOpen}
          width={1000}
          onCancel={() => {
            formikRef.current.resetForm();
            setIsOpen(false);
            setProject(false);
          }}
          footer={false}
        >
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <label>Proje Adi (Değiştirilebilir)</label>

            <Input
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
            />
          </div>
          <LoadingOverlay
            active={loadingOverlay}
            spinner
            text={"Yükleniyor..."}
          >
            <div style={styles.container}>
              <div style={styles.left}>
                Cari : {current.unvan} Hesap : {current.hesap}
                <h3>Önceki Projeler</h3>
                {!loading ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: 400,
                        overflowY: "auto",
                      }}
                    >
                      {projects.map((proje, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => setSelectedProject(i)}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: 5,
                              border: `1px solid ${
                                selectedProject === i ? "white" : "gray"
                              }`,
                              marginBottom: 5,
                              borderRadius: 90,
                              width: 280,
                              cursor: "pointer",
                              paddingLeft: 15,
                              color: `${
                                selectedProject === i ? "white" : "black"
                              }`,
                              backgroundColor:
                                selectedProject === i
                                  ? "var(--primary-color)"
                                  : "white",
                            }}
                          >
                            <span style={{ marginRight: 15 }}>
                              Proje Adı : {proje.projead}
                            </span>
                            <span>Proje No : {proje.projeno}</span>
                          </div>
                        );
                      })}
                    </div>
                    {projects[0] && (
                      <Button
                        onClick={() => createOfferWithProject()}
                        style={{
                          maxWidth: "80%",
                          marginLeft: "auto",

                          marginRight: 20,
                          marginTop: 20,
                        }}
                      >
                        Proje no ile devam et
                      </Button>
                    )}
                  </>
                ) : (
                  loading && <div>Yükleniyor...</div>
                )}
                {!projects[0] && <div>Hiç Proje Yok!</div>}
              </div>
              <div style={styles.right}>
                <h3 style={{ paddingLeft: 15 }}>Proje Oluştur</h3>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    vprojead: "",
                    vyet1: "",
                    vyet2: "",
                    vyetcep1: "",
                    vyetcep2: "",
                    vnakliye: "Müşteri gönderecek.",
                    vnakucr: "",
                    vurunindir: "Müşteriye ait.",
                    vmontaj: "",
                    vmontzam: "",
                    vmontar: "",
                    vsevkadr: "",
                    vsevkil: "",
                    vsevkilce: "",
                    vsvktip: "Kendi aracı",
                    vacik: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    MainStore.setLoading(true);
                    APIKit.post("/api/project/createproject", {
                      newProject: values,

                      project: offer,
                      status: "Onaylandı (Projeli)",
                      vhesap: current.hesap,
                    })
                      .then((res) => {
                        MainStore.setLoading(false);

                        message.success("Teklif onaylandı ve projeye atandı");
                        setIsOpen(false);
                        setProject(false);
                        loadData();
                        setTopOpen(false);
                      })
                      .catch((err) => {
                        MainStore.setLoading(false);
                        if (err?.response?.data)
                          message.error(err.response.data);
                        else message.error("Teklif onaylanamadı!");
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <input
                          name="vprojead"
                          value={values.vprojead}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Proje Adı"}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <input
                          name="vyet1"
                          value={values.vyet1}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Yetkili-1"}
                        />
                        <input
                          name="vyet2"
                          value={values.vyet2}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Yetkili-2"}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <input
                          name="vyetcep1"
                          value={values.vyetcep1}
                          type={"tel"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Yetkili-Cep-1"}
                        />
                        <input
                          name="vyetcep2"
                          value={values.vyetcep2}
                          type={"tel"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Yetkili-Cep-2"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            width: 110,
                            textAlign: "center",
                            marginBottom: 8,
                            marginLeft: 10,
                          }}
                        >
                          Nakliye Aracı:
                        </span>
                        <select
                          name="vnakliye"
                          value={values.vnakliye}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // style={{ marginLeft: 0 }}
                          style={styles.input}
                          placeholder={"Nakliye Aracı"}
                        >
                          <option>Müşteri gönderecek.</option>
                          <option>Biz ayarlayacağız.</option>
                        </select>
                        <input
                          name="vnakucr"
                          value={values.vnakucr}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Nakliye ücreti"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            width: 110,
                            textAlign: "center",
                            marginBottom: 8,
                            marginLeft: 10,
                          }}
                        >
                          Ürün indirme:
                        </span>
                        <select
                          name="vurunindir"
                          value={values.vurunindir}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Ürün indirme"}
                        >
                          <option>Müşteriye ait</option>
                          <option>Bize ait</option>
                        </select>
                        <input
                          name="vmontaj"
                          value={values.vmontaj}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Montaj"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            width: 110,
                            textAlign: "center",
                            marginBottom: 8,
                          }}
                        >
                          Montaj Tarihi:
                        </span>

                        <input
                          name="vmontar"
                          value={values.vmontar}
                          type={"date"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Montaj Tarihi"}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <textarea
                          name="vsevkadr"
                          value={values.vsevkadr}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.textarea}
                          placeholder={"Sevk Adresi"}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <input
                          name="vsevkil"
                          value={values.vsevkil}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Sevk il"}
                        />

                        <input
                          name="vsevkilce"
                          value={values.vsevkilce}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Sevk ilçe"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            width: 110,
                            textAlign: "center",
                            marginBottom: 8,
                          }}
                        >
                          Sevk Tipi :
                        </span>
                        <select
                          name="vsvktip"
                          value={values.vsvktip}
                          type={"text"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={styles.input}
                          placeholder={"Sevk Tipi"}
                        >
                          <option>Kendi Aracı</option>
                          <option>Bizim Aracımız</option>
                          <option>Nakliye</option>
                        </select>
                      </div>
                      <Button
                        style={{
                          marginLeft: 10,
                          backgroundColor: "var(--secondary-color)",
                          color: "white",
                        }}
                        onClick={() => {
                          formikRef.current.handleSubmit();
                        }}
                      >
                        Proje oluştur ve teklifi onayla.
                      </Button>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
            {status !== "Onaylandı (Projesiz)" &&
              (status === "Onaylandı (Projeli)" ||
                status === "Onayda Bekleyen") && (
                <div
                  style={{
                    paddingTop: 60,
                    minWidth: "100%",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (status === "Onaylandı (Projeli)") setConfirmProject();
                      else createOffer();
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    {status === "Onaylandı (Projeli)"
                      ? "Seçili proje ile devam et"
                      : "Projesiz devam et"}
                  </Button>
                </div>
              )}
          </LoadingOverlay>
        </Modal>
      );
    }
  )
);

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
  left: {
    width: "30%",
    borderRight: "1px solid gray",
  },
  input: {
    padding: 4,
    marginBottom: 10,
    width: "45%",
    borderRadius: 5,
    marginLeft: 13,
    border: "1px solid rgba(0,0,0,0.5)",
  },
  textarea: {
    padding: 4,
    marginBottom: 10,
    width: "92.8%",
    borderRadius: 5,
    marginLeft: 13,
    border: "1px solid rgba(0,0,0,0.5)",
  },
  right: {
    width: "50%",
    paddingRight: 20,
  },
};

export default OfferConfirmation;
