import React from "react";
import { Modal, Button } from "antd";
import styles from "./oldrevize.module.css";
import { numberToMoney } from "../../utils/util";
import moment from "moment";
import OfferDetails from "../offerDetails/OfferDetails";
import RevizeDetails from "../revizeDetails/RevizeDetails";

function OldRevizes({ isOpen, setIsOpen, selectedOffer, floatParser }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedRevize, setSelectedRevize] = React.useState([]);
  const [revizeOneBefore, setRevizeOneBefore] = React.useState([]);
  const calculatePrice = (offer) => {
    var withDiscountPrice = 0;
    var withoutDiscountPrice = 0;

    for (var ic = 0; ic < Object.keys(offer).length; ic++) {
      withDiscountPrice +=
        (((100 - offer[ic].discount3) / 100) *
          ((100 - offer[ic].discount2) / 100) *
          ((100 - offer[ic].discount1) / 100) *
          offer[ic].price *
          offer[ic].count -
          offer[ic].totalDiscount +
          floatParser(offer[ic].kataForez) * offer[ic].count +
          floatParser(offer[ic].kumasFarki) * offer[ic].count) *
          (floatParser(offer[ic].kdv) / 100) +
        (((100 - offer[ic].discount3) / 100) *
          ((100 - offer[ic].discount2) / 100) *
          ((100 - offer[ic].discount1) / 100) *
          offer[ic].price *
          offer[ic].count -
          offer[ic].totalDiscount +
          floatParser(offer[ic].kataForez) * offer[ic].count +
          floatParser(offer[ic].kumasFarki) * offer[ic].count);

      withoutDiscountPrice += offer[ic].price * offer[ic].count;
    }

    return { withDiscountPrice, withoutDiscountPrice };
  };

  return (
    <Modal
      title="Eski Revizeler"
      visible={isOpen}
      width={1000}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <div className={styles.container}>
        {selectedOffer.offers
          .slice(0)
          .reverse()
          .map((offer, i) => {
            return (
              <div key={i} className={styles.contItem}>
                <div className={styles.row}>
                  <span>
                    {offer[0].revizeExplanation
                      ? "Revize Sebebi:" + offer[0].revizeExplanation
                      : "İlk Teklif"}
                  </span>
                  <span>
                    {" "}
                    {offer[0].whoDidRevize
                      ? "Revize Yapan:" + offer[0].whoDidRevize
                      : ""}
                  </span>
                  <span>
                    KDV dahil fiyat :{" "}
                    {numberToMoney(
                      calculatePrice(offer).withoutDiscountPrice,
                      selectedOffer.currenyUnit
                    )}
                  </span>
                  <span>
                    Genel Toplam :{" "}
                    {numberToMoney(
                      calculatePrice(offer).withDiscountPrice,
                      selectedOffer.currenyUnit
                    )}
                  </span>
                </div>
                <div className={styles.row}>
                  {offer[0].whoDidRevize ? (
                    <>
                      <span>
                        Revize Tarihi:{" "}
                        {moment(offer[0].revizeDate).format("DD-MM-YYYY")}
                      </span>
                      <Button
                        onClick={() => {
                          setRevizeOneBefore([]);
                          setSelectedRevize(offer);

                          if (i !== selectedOffer.offers.length - 1)
                            setRevizeOneBefore(
                              selectedOffer.offers.slice(0).reverse()[i + 1]
                            );

                          setIsModalOpen(true);
                        }}
                        className={styles.button}
                      >
                        Revize Detayları
                      </Button>
                    </>
                  ) : (
                    <>
                      <span>
                        Teklif Oluşturulma Tarihi:{" "}
                        {moment(selectedOffer.createDateReal).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                      <Button
                        onClick={() => {
                          setRevizeOneBefore([]);
                          setSelectedRevize(offer);
                          setIsModalOpen(true);
                        }}
                        className={styles.button}
                      >
                        Revize Detayları
                      </Button>
                    </>
                  )}
                </div>
              </div>
            );
          })}

        {selectedRevize[0] ? (
          <RevizeDetails
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            revize={selectedRevize}
            withDiscountPrice={calculatePrice(selectedRevize).withDiscountPrice}
            withoutDiscountPrice={
              calculatePrice(selectedRevize).withoutDiscountPrice
            }
            discount={
              calculatePrice(selectedRevize).withoutDiscountPrice -
              calculatePrice(selectedRevize).withDiscountPrice
            }
            floatParser={floatParser}
            currenyUnit={selectedOffer.currenyUnit}
            revizeOneBefore={revizeOneBefore}
          />
        ) : null}
      </div>
    </Modal>
  );
}

export default OldRevizes;
