import React, { useState } from "react";
import styles from "./current.module.css";
import { useSelector, useDispatch } from "react-redux";
import APIKit from "../../shared/APIKit";
import { SELECT_CURRENT, SET_CURRENTS } from "../../actions/types";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ReactLoading from "react-loading";
import CurrentModal from "../../components/curretModal/CurrentModal";
import { message, Button } from "antd";
import { FaFirstdraft } from "react-icons/fa";
import { inject, observer } from "mobx-react";

const Currents = inject("MainStore")(
  observer((props) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const [isDoubleClicked, setIsDoubleClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const tableitems = [
      {
        label: "Hesap",
        value: "hesap",
        width: 150,
      },
      {
        label: "Müşteri Ünvanı",
        value: "unvan",
        width: 360,
      },
      {
        label: "Telefon",
        value: "telefon",
        width: 230,
      },
      {
        label: "Plasiyer",
        value: "plasiyer",
      },
      {
        label: "Adres",
        value: "adres",
        width: 500,
      },
      {
        label: "İl",
        value: "il",
      },
      {
        label: "İlçe",
        value: "ilce",
      },
      {
        label: "Mağaza",
        value: "magaza",
      },
    ];

    const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      setIsGridReady(true);
    };

    const onSelectionChanged = (params) => {
      if (isGridReady && gridColumnApi) {
        let selectedNodes = gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
        props.MainStore.setCurrent(selectedData[0]);
        props.setRefresh(!props.refresh);
      }
    };

    const gridOptions = {
      pagination: false,
    };

    const onRowDoubleClicked = () => {
      setIsOpen(true);
    };

    const getCaris = () => {
      setLoading(true);
      APIKit.get("/api/current/")
        .then(({ data }) => {
          console.log(data);
          props.MainStore.setCurrents(data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          props.MainStore.setCurrents([]);
          message.error(err?.response?.data);
        });
    };

    React.useEffect(() => {
      getCaris();
    }, []);

    if (loading)
      return (
        <div className={styles.loading}>
          <ReactLoading
            type={"balls"}
            color={"var(--background-color)"}
            height={120}
            width={120}
          />
        </div>
      );
    if (!props.MainStore.currents[0])
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: 10,
            }}
          >
            <Button onClick={() => getCaris()}>Yenile</Button>
          </div>

          <div className={styles.notFound}>
            <FaFirstdraft className={styles.icon} />
            <span>Hiç cari yok!</span>
          </div>
        </>
      );
    else
      return (
        <div className={styles.maincontainer}>
          <div
            id="myGrid"
            className="ag-theme-alpine"
            style={{ width: "100%", height: "100%" }}
          >
            <AgGridReact
              modules={[AllCommunityModules]}
              onGridReady={onGridReady}
              rowData={props.MainStore.currents}
              rowSelection="single"
              gridOptions={gridOptions}
              onRowDoubleClicked={onRowDoubleClicked}
              onSelectionChanged={onSelectionChanged}
              immutableData={false}
              getRowNodeId={(data) => data._id}
              defaultColDef={{
                width: 150,
                sortable: true,
                filter: "agTextColumnFilter",
                floatingFilter: true,
                editable: false,
                resizable: true,
              }}
            >
              {tableitems.map((item, index) => {
                return (
                  <AgGridColumn
                    key={index}
                    headerName={item.label}
                    field={item.value}
                    minWidth={item?.width}
                  />
                );
              })}
            </AgGridReact>
          </div>
          <CurrentModal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            current={props.MainStore.current}
          />
        </div>
      );
  })
);

export default Currents;
