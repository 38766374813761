import React, { useState } from "react";
import { Button, Input, Modal, Select } from "antd";
import styles from "./addcurrentmodal.module.css";
import moment from "moment";

const defaultValues = {
  vprojead: "",
  vyet1: "",
  vyet2: "",
  vyetcep1: "",
  vyetcep2: "",
  vnakliye: "Müşteri gönderecek.",
  vnakucr: "",
  vurunindir: "Müşteriye ait.",
  vmontaj: "",
  vmontzam: "",
  vmontar: moment().format("DD-MM-YYYY"),
  vsevkadr: "",
  vsevkil: "",
  vsevkilce: "",
  vsvktip: "Kendi aracı",
  vacik: "",
};

const ProjectModal = ({ isOpen, setIsOpen, onConfirmProject }) => {
  const [values, setValues] = useState(defaultValues);
  const { TextArea } = Input;
  return (
    <Modal
      title="Proje Ekle"
      centered
      visible={isOpen}
      onCancel={() => {
        setValues(defaultValues);
        setIsOpen(false);
      }}
      width={600}
      footer={false}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Input
            name="vprojead"
            type={"text"}
            value={values.vprojead}
            onChange={(e) => setValues({ ...values, vprojead: e.target.value })}
            className={styles.input}
            placeholder={"Proje Adı"}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Input
            name="vyet1"
            type={"text"}
            value={values.vyet1}
            onChange={(e) => setValues({ ...values, vyet1: e.target.value })}
            className={styles.input}
            placeholder={"Yetkili-1"}
          />
          <Input
            name="vyet2"
            type={"text"}
            value={values.vyet2}
            onChange={(e) => setValues({ ...values, vyet2: e.target.value })}
            className={styles.input}
            placeholder={"Yetkili-2"}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Input
            name="vyetcep1"
            type={"tel"}
            value={values.vyetcep1}
            onChange={(e) => setValues({ ...values, vyetcep1: e.target.value })}
            className={styles.input}
            placeholder={"Yetkili-Cep-1"}
          />
          <Input
            name="vyetcep2"
            type={"tel"}
            value={values.vyetcep2}
            onChange={(e) => setValues({ ...values, vyetcep2: e.target.value })}
            className={styles.input}
            placeholder={"Yetkili-Cep-2"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <span
              style={{
                width: 110,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 5,
              }}
            >
              Nakliye Aracı:
            </span>
            <Select
              name="vnakliye"
              type={"text"}
              style={{ marginLeft: 0, width: 250 }}
              className={styles.input}
              placeholder={"Nakliye Aracı"}
              value={values.vnakliye}
              onChange={(value) => setValues({ ...values, vnakliye: value })}
            >
              <Select.Option key={"Müşteri gönderecek."}>
                Müşteri gönderecek.
              </Select.Option>
              <Select.Option key={"Biz ayarlayacağız."}>
                Biz ayarlayacağız.
              </Select.Option>
            </Select>
          </div>

          <Input
            name="vnakucr"
            type={"text"}
            value={values.vnakucr}
            onChange={(e) => setValues({ ...values, vnakucr: e.target.value })}
            className={styles.input}
            style={{ maxWidth: 200 }}
            placeholder={"Nakliye ücreti"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <span
              style={{
                width: 110,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 5,
              }}
            >
              Ürün İndirme:
            </span>
            <Select
              name="vurunindir"
              type={"text"}
              style={{ marginLeft: 0, width: 250 }}
              className={styles.input}
              value={values.vurunindir}
              onChange={(value) => setValues({ ...values, vurunindir: value })}
              placeholder={"Ürün İndirme"}
            >
              <Select.Option key={"Müşteriye ait."}>
                Müşteriye ait.
              </Select.Option>
              <Select.Option key={"Bize ait."}>Bize ait.</Select.Option>
            </Select>
          </div>

          <Input
            name="vmontaj"
            type={"text"}
            value={values.vmontaj}
            onChange={(e) => setValues({ ...values, vmontaj: e.target.value })}
            style={{ maxWidth: 200 }}
            className={styles.input}
            placeholder={"Montaj"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textAlign: "center",
              marginBottom: 8,
              marginRight: 5,
            }}
          >
            Montaj Tarihi:
          </span>

          <Input
            name="vmontar"
            type={"date"}
            className={styles.input}
            value={values.vmontar}
            onChange={(e) => setValues({ ...values, vmontar: e.target.value })}
            placeholder={"Montaj Tarihi"}
            style={{ maxWidth: 250 }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <TextArea
            name="vsevkadr"
            type={"text"}
            className={styles.textarea}
            value={values.vsevkadr}
            onChange={(e) => setValues({ ...values, vsevkadr: e.target.value })}
            style={{ marginBottom: 10 }}
            placeholder={"Sevk Adresi"}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <Input
            name="vsevkil"
            type={"text"}
            value={values.vsevkil}
            onChange={(e) => setValues({ ...values, vsevkil: e.target.value })}
            className={styles.input}
            placeholder={"Sevk il"}
          />

          <Input
            name="vsevkilce"
            type={"text"}
            value={values.vsevkilce}
            onChange={(e) =>
              setValues({ ...values, vsevkilce: e.target.value })
            }
            className={styles.input}
            placeholder={"Sevk ilçe"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            <span
              style={{
                width: 110,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 5,
              }}
            >
              Sevk Tipi:
            </span>
            <Select
              name="vsvktip"
              type={"text"}
              style={{ marginLeft: 0, width: 250 }}
              className={styles.input}
              value={values.vsvktip}
              onChange={(value) => {
                setValues({ ...values, vsvktip: value });
              }}
              placeholder={"Sevk Tipi"}
            >
              <Select.Option key={"Kendi Aracı."}>Kendi Aracı.</Select.Option>
              <Select.Option key={"Bizim Aracımız."}>
                Bizim Aracımız.
              </Select.Option>
              <Select.Option key={"Nakliye."}>Nakliye</Select.Option>
            </Select>
          </div>
        </div>
        <div style={{ width: "100%", alignItems: "center" }}>
          <Button
            onClick={() => {
              onConfirmProject(values);
              setValues(defaultValues);
              setIsOpen(false);
            }}
            style={{
              backgroundColor: "var(--secondary-color)",
              color: "white",
              width: "100%",
            }}
          >
            Projeyi Ekle
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectModal;
