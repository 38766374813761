import React, { useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import store from "./store";
import { setClientToken } from "./shared/APIKit";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "mobx-react";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import Login from "./layout/login/Login";
import Home from "./layout/home/Home";
import { AiOutlineClose } from "react-icons/ai";
import { inject, observer } from "mobx-react";
import "./App.css";

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 2000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div className="alertStyle">
    {message}
    <button onClick={close} className="alertClose">
      <AiOutlineClose />
    </button>
  </div>
);

const App = inject("MainStore")(
  observer((props) => {
    const handleInit = useCallback(async () => {
      if (props.MainStore.auth.isAuthenticated === true) {
        const token = await localStorage.getItem("jwtToken");
        console.log("Init Token", token);
        setClientToken(token);
        const decoded = jwt_decode(token);

        props.MainStore.setAuth(decoded);

        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          props.MainStore.logOut();
          window.location.href = "/login";
        }
      }
      window.onbeforeunload = function () {
        return "";
      };
    }, []);
    React.useEffect(() => {
      setTimeout(() => {
        handleInit();
      }, 50);
    }, [handleInit]);

    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <Router>
          <div className="App">
            <Switch>
              <Route path="/login" component={Login} />
              <Switch>
                <PrivateRoute path="/home" component={Home} />
                <Redirect from={""} to={"/home"} />
              </Switch>
              <Redirect from={""} to={"/login"} />
            </Switch>
          </div>
        </Router>
      </AlertProvider>
    );
  })
);
export default App;
